import * as React from 'react';
import { ICompositionLayer } from '../../constants/snippets';
import { LayerInfoItem } from './LayerInfoItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ITimelineState } from '../../redux/reducers/timeline';
import { updateTimelineState } from '../../redux/actions/timeline';
import { IProject } from '../../redux/reducers/project';

interface ILayerInfoPanelProps {
    compositionId: string;
    showCopyPropIds: boolean;
    layers: ICompositionLayer[];
    timeline: ITimelineState;
    project: IProject;
    layerSources: any;
    updateTimelineState(s: any): void;
    onLayerChange(layer: ICompositionLayer): void;
    onLayerMove(index: number, shift: number): void;
    onSelect(layerId: string);
    onMultiSelect(layerId: string);
    onSetLayerMatte(layerId: string, matteLayerId: string);
    onSelectKeyframe(keyframeId: string, frameNumber: number);
    onExpand(layerId: string);
    onNotification(e);
    onError(e);
}

class LayerInfoPanel extends React.PureComponent<ILayerInfoPanelProps, undefined> {
    private evtHandlers: any = {};

    constructor(props) {
        super(props);
    }

    public render() {
        const {
            layers,
            timeline: {
                activeTimelineLayer,
                activeMultiSelectLayers,
                expandedLayers,
                activeFrame,
                activeKeyframes
            },
            layerSources
        } = this.props;

        if (!layers) {
            return null;
        }

        return (
            <div id='layer-info-panel'>
                <div className='layer-info-items'>
                    {layers
                        .slice(0)
                        .reverse()
                        .map((layer, i) => {
                            const active =
                                layer.id === activeTimelineLayer ||
                                activeMultiSelectLayers.indexOf(layer.id) !== -1;

                            const expanded = expandedLayers.indexOf(layer.id) !== -1;

                            const onSelectKeyframe = (keyframeId, frameNumber) => {
                                this.props.onSelect(layer.id);
                                this.props.onSelectKeyframe(keyframeId, frameNumber);
                            };
                            return (
                                <LayerInfoItem
                                    showCopyPropIds={this.props.showCopyPropIds}
                                    onNotification={this.props.onNotification}
                                    onError={this.props.onError}
                                    updateTimelineState={this.props.updateTimelineState}
                                    activeTimelineLayer={activeTimelineLayer}
                                    activeFrame={activeFrame}
                                    activeKeyframes={activeKeyframes}
                                    layerSources={layerSources}
                                    index={i}
                                    active={active}
                                    expanded={expanded}
                                    onExpand={this.props.onExpand}
                                    layers={layers}
                                    onMove={this.props.onLayerMove}
                                    key={layer.id}
                                    config={layer}
                                    onSelect={this.props.onSelect}
                                    onSelectKeyframe={onSelectKeyframe}
                                    onMultiSelect={this.props.onMultiSelect}
                                    onSetLayerMatte={this.props.onSetLayerMatte}
                                    onChange={this.props.onLayerChange}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        timeline: state.timeline[state.project.compositionId],
        project: state.project,
        showCopyPropIds: state.editor.showCopyPropIds,
        layerSources: state.layerSources
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerInfoPanel);
