import Papa from 'papaparse';
import {
    COMPOSITION_LAYER_TYPES,
    MEDIA_VARIABLE_TYPES,
    SOURCE_TYPES,
    TAG_TYPES,
    VARIABLE_TYPES
} from '../constants/story';
import { getTagFilter, parseInventoryFromRowData } from './story';
import { api } from '../constants/app';
import store from '../redux/store';
import { addItemToAssetCache, clearAssetCache } from '../redux/actions/asset-cache';
import {
    ASSET_LAYER_TYPES,
    DATASET_ERRORS,
    DEFAULT_EXTENSION_MAP,
    MAX_DATASET_LENGTH,
    SWAPPABLE_LAYER_TYPES
} from '../constants/dataset';
import axios from 'axios';
import { cartesian } from './general';
import { dataEditor as copy } from '../constants/copy';

export const generateNewCSV = (name, variables) => {
    const dataset = [];
    const idRow = [];
    const previewRow = [];

    for (const vKey in variables) {
        if (variables.hasOwnProperty(vKey)) {
            const variable = variables[vKey];
            idRow.push(vKey);
            if (variable?.previewItem?.src) {
                const { url, src } = variable.previewItem;
                const previewVal = url ? url : src;
                previewRow.push(previewVal);
            } else if (variable?.defaultItem?.src) {
                const { url, src } = variable.defaultItem;
                const previewVal = url ? url : src;
                previewRow.push(previewVal);
            } else {
                previewRow.push('');
            }
        }
    }

    dataset.push(idRow);
    dataset.push(previewRow);

    // If the ID row and preview row are both empty - add some sample data
    if (idRow.length === 0 && previewRow.length === 0) {
        idRow.push('Test');
        previewRow.push('Lorem Ipsum');
    }

    const unparsed = Papa.unparse(dataset, { skipEmptyLines: true });
    return convertDatasetCSVStringToFile(unparsed, name);
};

export const convertDatasetCSVStringToFile = (data, name) => {
    const blob = new Blob([data], { type: 'text/csv' });
    return new File([blob], `${name}.csv`, { type: 'text/csv' });
};

// Parse the CSV string into an array of arrays
export const formatDatasetArrayFromCSV = (dataset) => {
    const newDataset = { ...dataset };
    const parsed = Papa.parse(dataset.data);
    const headers = parsed.data.shift();
    newDataset.data = parsed.data;
    newDataset.headers = headers || [];
    return newDataset;
};

// Parse an array of arrays back into a CSV
export const formatDatasetToCSVFromArray = (dataset) => {
    const newData = [...dataset.data];
    newData.unshift(dataset.headers);
    const unparsed = Papa.unparse(newData);
    const file = convertDatasetCSVStringToFile(unparsed, dataset.name);
    return file;
};

export const updateDataset = (dataset, changes) => {
    const newData = [...dataset];
    for (const change of changes) {
        const rowIndex = change[0];
        const newRow = newData[rowIndex] ? [...newData[rowIndex]] : getNewRow(newData[0].length);
        if (typeof change[3] === 'boolean') {
            newRow[change[1]] = change[3];
        } else {
            newRow[change[1]] = change[3] || '';
        }

        newData[rowIndex] = newRow;
    }

    return newData;
};

export const addColumnHeader = (headers, header) => {
    const newHeaders = [...headers];
    newHeaders.push(header);
    return newHeaders;
};

export const addColumnToDataset = (dataset) => {
    const newData = [];
    for (const row of dataset) {
        const newRow = [...row];
        newRow.push('');
        newData.push(newRow);
    }
    return newData;
};

export const getNewRow = (vals) => {
    const newRow = [];
    for (let i = 0; i < vals; i++) {
        newRow.push('');
    }
    return newRow;
};

export const addRowToDataset = (dataset, index, rows) => {
    const newData = [...dataset];
    const vals = newData[0].length;
    const newRows = [];
    for (let i = 0; i < rows; i++) {
        newRows.push(getNewRow(vals));
    }
    newData.splice(index, 0, ...newRows);
    return newData;
};

export const addDuplicateRowToDataset = (dataset, index) => {
    const newData = [...dataset];
    const row = [...newData[index]];
    newData.splice(index, 0, row);
    return newData;
};

export const removeRowsFromDataset = (dataset, range) => {
    const startIndex = range[0];
    const endIndex = range[range.length - 1];
    const itemsToRemove = endIndex - startIndex + 1;
    const newData = [...dataset];
    newData.splice(startIndex, itemsToRemove);
    return newData;
};

export const removeColumnsFromDataset = (dataset, range) => {
    const orderedCols = [...range].sort().reverse();
    const newData = [];
    for (const row of dataset) {
        const newRow = [...row];
        for (const col of orderedCols) {
            newRow.splice(col - 1, 1); // -1 due to spliced in column for actions
        }
        newData.push(newRow);
    }
    return newData;
};

export const removeColumnsFromHeaders = (headers, range) => {
    const orderedCols = [...range].sort().reverse();
    const newHeaders = [...headers];
    for (const col of orderedCols) {
        newHeaders.splice(col - 1, 1); // -1 due to spliced in column for actions
    }
    return newHeaders;
};

export const getDatasetErrors = async (
    dataset,
    activeComposition,
    headers,
    variables,
    activeStoryId
) => {
    store.dispatch(clearAssetCache());

    const newErrors = [];
    for (const row of dataset) {
        console.debug('Dataset Error Check - Check row');
        console.debug(row);

        const dataErrors = checkRowForMissingData(row, headers, variables);

        const mediaErrors = await checkRowForBrokenURLs(row, headers, variables);
        // Can't find asset, asset too short
        const assetErrors = await checkCompForMissingAssets(
            activeComposition,
            row,
            headers,
            activeStoryId
        );

        const rowErrors = [...dataErrors, ...mediaErrors, ...assetErrors];

        console.debug('Dataset Error Check - Found errors:');
        console.debug(rowErrors);
        newErrors.push(rowErrors);
    }
    return newErrors;
};

export const checkCompForMissingAssets = (composition, row, headers, storyId): Promise<any> => {
    console.debug(`Dataset Error Check- Check composition for missing assets`);

    return new Promise((resolve, reject) => {
        const inventory = parseInventoryFromRowData(headers, row);

        console.debug(`Dataset Error Check - Inventory:`);
        console.debug(inventory);

        let rowErrors = [];

        const layerPromises = [];

        for (const layer of composition.layers) {
            const { options, type, audio_enabled, video_enabled } = layer;

            // If it's a nested comp layer, recursively get those errors
            if (
                layer.type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION &&
                options?.source?.from === SOURCE_TYPES.ASSET
            ) {
                layerPromises.push(
                    new Promise<void>((res, rej) => {
                        getCachedAssetFromId(options.source.asset_id)
                            .then((r) => {
                                if (r.data) {
                                    const compData = JSON.parse(r.data);
                                    checkCompForMissingAssets(compData, row, headers, storyId)
                                        .then((e) => {
                                            if (e.length > 0) {
                                                rowErrors = [...rowErrors, ...e];
                                            }
                                            resolve(rowErrors);
                                        })
                                        .catch((e) => {
                                            rej(e);
                                        });
                                } else {
                                    rej('Comp not found');
                                }
                            })
                            .catch((e) => {
                                rej(e);
                            });
                    })
                );
            } else if (
                SWAPPABLE_LAYER_TYPES.indexOf(type) !== -1 &&
                options?.source?.from === SOURCE_TYPES.ASSET_TAGS
            ) {
                if (
                    ((type === COMPOSITION_LAYER_TYPES.VIDEO ||
                        type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION) &&
                        (video_enabled || audio_enabled)) ||
                    (type === COMPOSITION_LAYER_TYPES.AUDIO && audio_enabled) ||
                    (type !== COMPOSITION_LAYER_TYPES.VIDEO &&
                        type !== COMPOSITION_LAYER_TYPES.AUDIO &&
                        video_enabled)
                ) {
                    const tags = getTagFilter(options.source.asset_tags, inventory, false, true);
                    // Resolve the asset(s)
                    layerPromises.push(
                        new Promise<void>((res, rej) => {
                            checkForAssetErrors(
                                tags,
                                type,
                                storyId,
                                layer,
                                row,
                                headers,
                                composition
                            )
                                .then((e: any) => {
                                    if (e.length > 0) {
                                        rowErrors = [...rowErrors, ...e];
                                    }
                                    res();
                                })
                                .catch((e) => {
                                    rej();
                                });
                        })
                    );
                }
            }
        }

        Promise.all(layerPromises)
            .then(() => {
                resolve(rowErrors);
            })
            .catch((e) => {
                console.error('Error resolving assets');
                console.error(e);
            });
    });
};

export const checkRowForMissingData = (row, headers, variables) => {
    const errors = [];
    for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        const variable = variables[header];
        const val = row[i];

        // If there is a variable found, this column is dynamic
        if (variable) {
            // If there is no value, and it's not optional, return an error
            if (!variable.optional && !val) {
                errors.push({
                    type: DATASET_ERRORS.MISSING_REQUIRED_VALUE,
                    data: {
                        variableId: variable.id,
                        value: val,
                        index: i
                    }
                });

                // If the variable is an enum (list) and the value isn't in the options, return an error
            } else if (variable.type === VARIABLE_TYPES.ENUM) {
                const options = variable?.defaultItem?.options || [];
                if (options.indexOf(val) === -1) {
                    errors.push({
                        type: DATASET_ERRORS.INCORRECT_VALUE_ENUM,
                        data: {
                            variableId: variable.id,
                            value: val,
                            index: i
                        }
                    });
                }
            }
        }
    }
    return errors;
};

export const checkRowForBrokenURLs = (row, headers, variables): Promise<any> => {
    return new Promise((resolve, reject) => {
        const rowErrors = [];
        const filePromises = [];
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            const variable = variables[header];
            const val = row[i];

            // If there is a variable found, this column is dynamic
            if (variable && MEDIA_VARIABLE_TYPES.indexOf(variable.type) !== -1 && val) {
                filePromises.push(
                    new Promise<void>((res, rej) => {
                        getCachedFileFromURL(val)
                            .then((fileResponse) => {
                                if (fileResponse.error) {
                                    rowErrors.push({
                                        type: DATASET_ERRORS.BROKEN_URL,
                                        data: {
                                            header,
                                            url: fileResponse.url,
                                            status: fileResponse.status,
                                            statusText: fileResponse.statusText
                                        }
                                    });
                                } else if (fileResponse.type.indexOf(variable.type) === -1) {
                                    rowErrors.push({
                                        type: DATASET_ERRORS.WRONG_TYPE,
                                        data: {
                                            header,
                                            url: fileResponse.url,
                                            type: fileResponse.type,
                                            varName: variable.name,
                                            varType: variable.type
                                        }
                                    });
                                }
                                res();
                            })
                            .catch((e) => {
                                rej();
                            });
                    })
                );
            }
        }
        Promise.all(filePromises)
            .then(() => {
                resolve(rowErrors);
            })
            .catch((e) => {
                resolve(rowErrors);
            });
    });
};

// Check the asset cache for assets, if it's not there, use the API to get it
export const getCachedAssetsFromTags = (tags, type, storyId): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const cache = store.getState().assetCache;
        const cacheKey = `${type}-${tags}`;
        if (cache[cacheKey]) {
            resolve(cache[cacheKey]);
        } else {
            api.getAssets({ type, tags }, storyId)
                .then((assetResponse) => {
                    const cacheItem = { [cacheKey]: assetResponse };
                    store.dispatch(addItemToAssetCache(cacheItem));
                    resolve(assetResponse);
                })
                .catch((e) => {
                    reject(e);
                });
        }
    });
};

export const getCachedFileFromURL = (url): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const cache = store.getState().assetCache;
        const cacheKey = url;
        if (cache[cacheKey]) {
            resolve(cache[cacheKey]);
        } else {
            axios
                .get(url)
                .then((res) => {
                    const { status, statusText, headers } = res;
                    const data = { status, statusText, url, type: headers['content-type'] };
                    const cacheItem = { [cacheKey]: data };
                    store.dispatch(addItemToAssetCache(cacheItem));
                    resolve(data);
                })
                .catch((e) => {
                    const status = e.response ? e.response.status : '';
                    const statusText = e.response ? e.response.statusText : e;
                    const data = { status, statusText, error: true, url };
                    const cacheItem = { [cacheKey]: data };
                    store.dispatch(addItemToAssetCache(cacheItem));
                    resolve(data);
                });
        }
    });
};

// Check the asset cache for a specitic asset, if it's not there, use the API to get it
export const getCachedAssetFromId = (assetId): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const cache = store.getState().assetCache;
        const cacheKey = assetId;
        if (cache[cacheKey]) {
            resolve(cache[cacheKey]);
        } else {
            api.getAssetItem(assetId)
                .then((assetResponse) => {
                    const cacheItem = { [cacheKey]: assetResponse };
                    store.dispatch(addItemToAssetCache(cacheItem));
                    resolve(assetResponse);
                })
                .catch((e) => {
                    reject(e);
                });
        }
    });
};

export const checkForAssetErrors = (tags, type, storyId, layer, row, headers, composition) => {
    const {
        name,
        start_frame,
        end_frame,
        type: layerType,
        options: {
            source: { optional }
        },
        audio_enabled,
        video_enabled
    } = layer;

    const { frames } = composition;
    const layerDuration = Math.min(end_frame, frames) - start_frame;

    let assetErrors = [];

    return new Promise((resolve, reject) => {
        getCachedAssetsFromTags(tags, type, storyId)
            .then((assetResponse) => {
                const { assets } = assetResponse;
                // If there are no assets, and the type was video, check with the type being video_composition (nesting)
                if (
                    assets.length === 0 &&
                    type === COMPOSITION_LAYER_TYPES.VIDEO &&
                    (video_enabled || audio_enabled)
                ) {
                    checkForAssetErrors(
                        tags,
                        COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION,
                        storyId,
                        layer,
                        row,
                        headers,
                        composition
                    )
                        .then((e: any) => {
                            if (e.length > 0) {
                                assetErrors = [...assetErrors, ...e];
                            }
                            resolve(assetErrors);
                        })
                        .catch((e) => {
                            reject(e);
                        });

                    // If there were no assets returned, return an error for this layer
                } else if (assets.length === 0 && !optional) {
                    assetErrors.push({
                        type: DATASET_ERRORS.NO_ASSET_FOUND,
                        data: {
                            layerType,
                            layerName: name,
                            tags
                        }
                    });

                    resolve(assetErrors);
                } else {
                    const asset = assets[0];
                    if (
                        type === COMPOSITION_LAYER_TYPES.VIDEO &&
                        (video_enabled || audio_enabled)
                    ) {
                        const { frame_count, name: assetName } = asset;
                        // If the video asset is too short, return an error
                        if (frame_count < layerDuration) {
                            assetErrors.push({
                                type: DATASET_ERRORS.ASSET_TOO_SHORT,
                                data: {
                                    layerName: name,
                                    assetName,
                                    tags,
                                    difference: layerDuration - frame_count
                                }
                            });
                        }
                        resolve(assetErrors);

                        // Recursively dig into nested comp
                    } else if (
                        type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION &&
                        (video_enabled || audio_enabled)
                    ) {
                        console.debug(`Dataset Error Check- Follow nested comp from tags`);
                        const compData = JSON.parse(asset.data);
                        checkCompForMissingAssets(compData, row, headers, storyId)
                            .then((e) => {
                                if (e.length > 0) {
                                    assetErrors = [...assetErrors, ...e];
                                }
                                resolve(assetErrors);
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    } else {
                        resolve(assetErrors);
                    }
                }
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const getRequiredAssetsFromComp = (composition, row, headers, storyId): Promise<any> => {
    return new Promise((resolve, reject) => {
        let assetList = [];
        const assetPromises = [];
        const inventory = parseInventoryFromRowData(headers, row);
        for (const layer of composition.layers) {
            const { options, type } = layer;

            // If it's a nested comp layer, recursively get those errors
            if (
                type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION &&
                options?.source?.from === SOURCE_TYPES.ASSET
            ) {
                const assetId = options?.source.asset_id;
                //  Nested comp

                assetPromises.push(
                    new Promise<void>((compResolve, compReject) => {
                        getCachedAssetFromId(assetId)
                            .then((res) => {
                                if (res.data) {
                                    const compData = JSON.parse(res.data);
                                    getRequiredAssetsFromComp(compData, row, headers, storyId)
                                        .then((a) => {
                                            if (a && a.length > 0) {
                                                assetList = [...assetList, ...a];
                                            }
                                            compResolve();
                                        })
                                        .catch((e) => {
                                            compReject(e);
                                        });
                                }
                            })
                            .catch((e) => {
                                compReject(e);
                            });
                    })
                );
            } else if (
                ASSET_LAYER_TYPES.indexOf(type) !== -1 &&
                options?.source?.from === SOURCE_TYPES.ASSET_TAGS
            ) {
                assetPromises.push(
                    new Promise<void>((assetResolve, assetReject) => {
                        let filename = '';
                        for (const tag of options.source.asset_tags) {
                            const { type: tagType, value } = tag;
                            let section;
                            if (tagType === TAG_TYPES.TEXT) {
                                section = value;
                            } else if (tagType === TAG_TYPES.VARIABLE) {
                                section = inventory[value];
                            }

                            if (section) {
                                if (filename !== '') {
                                    filename += '_';
                                }
                                filename += section;
                            }
                        }
                        filename += `.${DEFAULT_EXTENSION_MAP[type]}`;
                        assetList.push(filename);
                        assetResolve();
                    })
                );
            }
        }
        Promise.all(assetPromises)
            .then(() => {
                resolve(assetList);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const getCellType = (variable) => {
    if (variable) {
        switch (variable.type) {
            case VARIABLE_TYPES.ENUM:
                return 'dropdown';
            case VARIABLE_TYPES.NUMBER:
                return 'numeric';
            case VARIABLE_TYPES.BOOLEAN:
                return 'checkbox';
        }
        return 'text';
    } else {
        return 'text';
    }
};

export const getCellSource = (variable) => {
    if (variable) {
        switch (variable.type) {
            case VARIABLE_TYPES.ENUM:
                return variable?.defaultItem?.options || [];
        }
    }
};

export const generateSampleDataHandler = (variables) => {
    const srcDataArrays = [];
    const headers = [];

    // loop through the variables, any var that is an enum, put the options in the source array, for any other var, put the preview, or default
    for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
            const variable = variables[key];
            headers.push(key);
            if (variable.type === 'enum') {
                const options =
                    variable.defaultItem?.options && variable.defaultItem.options.length > 0
                        ? variable.defaultItem.options
                        : [''];
                srcDataArrays.push(options);
            } else {
                const isMedia = MEDIA_VARIABLE_TYPES.indexOf(variable.type) !== -1;
                if (variable.previewItem) {
                    const { url, src } = variable.previewItem;
                    const previewVal = isMedia ? url : src;
                    srcDataArrays.push([previewVal]);
                } else if (variable.defaultItem) {
                    const { url, src } = variable.defaultItem;
                    const defaultVal = isMedia ? url : src;
                    srcDataArrays.push([defaultVal]);
                } else {
                    srcDataArrays.push(['']);
                }
            }
        }
    }

    // If there are no variables, alert and return null
    if (srcDataArrays.length === 0) {
        alert(copy.generateNoVarsError);
        return null;
    }

    const data = cartesian(srcDataArrays);

    if (data.length > MAX_DATASET_LENGTH) {
        data.length = MAX_DATASET_LENGTH;
        alert(copy.overGenerateError.replace('[MAX]', MAX_DATASET_LENGTH.toString()));
    }

    const dataset = {
        data,
        headers
    };

    return dataset;
};

const multiElementSplicer = (range, data, finalIndex, offset = false) => {
    let toIndex = finalIndex;

    if (offset) {
        toIndex--;
    }
    const toBeFilteredDatas = [];
    const toBeFilteredIndex = [];
    for (const i in range) {
        if (i) {
            let tempIndex = range[i];
            if (offset) {
                tempIndex--;
            }
            const tempVal = data[tempIndex];
            toBeFilteredDatas.push(tempVal);
            toBeFilteredIndex.push(tempIndex);
        }
    }
    const newData = data.filter((_, index) => !toBeFilteredIndex.includes(index));
    for (let i = 0; i < toBeFilteredDatas.length; i++) {
        const newIndex = toIndex + i;
        newData.splice(newIndex, 0, toBeFilteredDatas[i]);
    }

    return newData;
};

export const moveColumnsHandler = (moveCol, finalIndex, activeDataset) => {
    const newActiveDataset = { ...activeDataset };
    const { headers, data, column_widths } = newActiveDataset;
    const toIndex = finalIndex - 1;
    const fromIndex = moveCol[0] - 1;
    const headerFromIndex = headers[fromIndex];
    const columnWidthFromIndex = column_widths ? column_widths[fromIndex] : null;
    const newDataSet = [];
    let newHeaders;
    let newColumnWidths;
    if (moveCol.length === 1) {
        newHeaders = headers.filter((_, index) => index !== fromIndex);
        newHeaders.splice(toIndex, 0, headerFromIndex);
        if (column_widths) {
            newColumnWidths = column_widths.filter((_, index) => index !== fromIndex);
            newColumnWidths.splice(toIndex, 0, columnWidthFromIndex);
        }
        for (const i in data) {
            if (i) {
                const itemFromIndex = data[i][fromIndex];
                const newData = data[i].filter((_, index) => index !== fromIndex);
                newData.splice(toIndex, 0, itemFromIndex);
                newDataSet.push(newData);
            }
        }
    }
    if (moveCol.length > 1 && moveCol[0] !== finalIndex) {
        newHeaders = multiElementSplicer(moveCol, headers, finalIndex, true);
        if (column_widths) {
            newColumnWidths = multiElementSplicer(moveCol, column_widths, finalIndex, true);
        }
        for (const idx in data) {
            if (idx) {
                const filteredData = [];
                const filteredIndex = [];
                for (const i in moveCol) {
                    if (i) {
                        const tempIndex = moveCol[i] - 1;
                        const tempVal = data[idx][tempIndex];
                        filteredData.push(tempVal);
                        filteredIndex.push(tempIndex);
                    }
                }
                const newData = data[idx].filter((_, index) => !filteredIndex.includes(index));
                for (let i = 0; i < filteredData.length; i++) {
                    const newIndex = toIndex + i;
                    newData.splice(newIndex, 0, filteredData[i]);
                }
                newDataSet.push(newData);
            }
        }
    }

    newActiveDataset.data = newDataSet;
    newActiveDataset.headers = newHeaders;
    if (newColumnWidths) {
        newActiveDataset.column_widths = newColumnWidths;
    }
    return newActiveDataset;
};

export const moveRowsHandler = (moveRow, finalIndex, activeDataset) => {
    const newActiveDataset = { ...activeDataset };
    const { data } = newActiveDataset;
    const fromIndex = moveRow[0];
    const dataFromIndex = data[fromIndex];

    let newData;

    if (moveRow.length === 1) {
        newData = data.filter((d) => d !== dataFromIndex);
        newData.splice(finalIndex, 0, dataFromIndex);
    }

    if (moveRow.length > 1 && moveRow[0] !== finalIndex) {
        newData = multiElementSplicer(moveRow, data, finalIndex);
    }
    newActiveDataset.data = newData;
    return newActiveDataset;
};

export const getErrorCopy = (error) => {
    const { type, data } = error;
    switch (type) {
        case DATASET_ERRORS.ASSET_TOO_SHORT:
            return `Asset "${data.assetName}" pulled in for layer "${data.layerName}" with the tags "${data.tags}" is ${data.difference} frames too short.`;
        case DATASET_ERRORS.WRONG_TYPE:
            return `The file at "${data.url}" pulled in for variable "${data.varName}" has the type of "${data.type}", which is incompatible with the variable type "${data.varType}"`;
        case DATASET_ERRORS.INCORRECT_VALUE_ENUM:
            return `Invalid value "${data.value}" passed into list variable "${data.variableId}".`;
        case DATASET_ERRORS.MISSING_REQUIRED_VALUE:
            return `Missing value for required variable "${data.variableId}".`;
        case DATASET_ERRORS.NO_ASSET_FOUND:
            return `No ${data.layerType} asset found for layer "${data.layerName}" when using the tags "${data.tags}".`;
        case DATASET_ERRORS.BROKEN_URL:
            return `The URL "${data.url}" passed into the "${data.header}" column is broken, and responded with "${data.status} ${data.statusText}"`;
    }
};
