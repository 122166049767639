import * as React from 'react';
import { bindActionCreators, EmptyObject } from 'redux';
import { connect } from 'react-redux';
import {
    Tabs,
    ITab,
    VideoPlayer,
    ImagePlayer,
    AudioPlayer,
    PreviewNotAvailable,
    addAssetTag,
    deleteAssetTag,
    updateAssetName,
    ImposiumDropdown,
    Modal,
    FontAssetPreview,
    Button,
    doAssetTableHydration
} from '@imposium-hub/components';
import { VIEWER_LABELS, VIEWER_TYPES } from '../../constants/viewer';
import { ASSET_TYPES, SCENE_TYPES } from '../../constants/story';
import TimelineVideoPlayer from './TimelineVideoPlayer';
import {
    setActiveViewer,
    closeViewer,
    closeOtherViewers,
    closeRightViewers,
    addViewer
} from '../../redux/actions/story';
import { updateEditorConfig } from '../../redux/actions/editor';
import CutPreviewPlayer from './CutPreviewPlayer';
import {
    IStory,
    IVariables,
    IVideoFile,
    IViewer,
    NEW_EMPTY_DEFAULT_VIEWER
} from '../../constants/snippets';
import StoryPreviewPlayer from './StoryPreviewPlayer';
import { HEADER_HEIGHT } from '../../constants/editor';
import CompositionSettings from '../CompositionSettings';
import { getStoryType } from '../../util/story';
import { api } from '../../constants/app';
import { settings } from '../../constants/copy';
import JobLog from './JobLog';
import CompositionPreviewPlayer from './CompositionPreviewPlayer';
import LayeredCompositionPreview from './composition-preview/LayeredCompositionPreview';
import NewCompositionInterface from '../NewCompositionInterface';
import AssetContextMenu from '../AssetContextMenu';
import HTMLAssetEditor from './HTMLAssetEditor';
import VideoControls from './VideoControls';
import { createAsset } from '../../redux/actions/asset';
import { video as copy } from '../../constants/copy';
import { ICON_CUT } from '../../constants/icons';
import AssetDetails from '../assets/AssetDetails';
import { updateTimelineState, clearTimelineState } from '../../redux/actions/timeline';
import { IProject } from '../../redux/reducers/project';
import { ITimelineState } from '../../redux/reducers/timeline';
import { openConfirmModal } from '../../util/ui';

interface IViewerProps {
    config: IViewer;
    videoFile?: IVideoFile;
    variables?: IVariables;
    compositions: any;
    setActiveViewer(k: string): void;
    updateEditorConfig(i: any): void;
    updateTimelineState(s: any): void;
    clearTimelineState(id: string): void;
    closeViewer(k: string): void;
    closeOtherViewers(k: string): void;
    closeRightViewers(k: string): void;
    varsOpen: boolean;
    deleteAssetTag(apiInstance: any, id: string, tags: string);
    addAssetTag(apiInstance: any, id: string, tags: string);
    updateAssetName(apiInstance: any, id: string, newName: string);
    addViewer(c: any): void;
    errors: any;
    story: IStory;
    changes: any;
    createAsset(asset: EmptyObject, data: EmptyObject);
    doAssetTableHydration: (apiInstance: any, storyId: string, onErr?: () => any) => any;
    assetList: any;
    project: IProject;
    timeline: ITimelineState;
}

interface IViewerState {
    showAssetDetails: boolean;
    activeAsset: any;
    trimAssetState: any;
    compSettingsModalOpen: boolean;
    inPoint: number;
    outPoint: number;
    showTrim: boolean;
}

class Viewer extends React.PureComponent<IViewerProps, IViewerState> {
    constructor(props) {
        super(props);

        this.state = {
            showAssetDetails: false,
            compSettingsModalOpen: false,
            activeAsset: null,
            trimAssetState: null,
            inPoint: 0,
            outPoint: 0,
            showTrim: false
        };
    }

    componentDidUpdate(prevProps: Readonly<IViewerProps>, prevState: Readonly<IViewerState>): void {
        const {
            config,
            story,
            assetList: { assets }
        } = this.props;
        const { showTrim } = this.state;

        if (story && prevProps.story && story.id !== prevProps.story.id) {
            this.checkForPreview();
        }

        if (config.activeViewer !== prevProps.config.activeViewer) {
            this.varBtnCheck();
            this.checkForAsset();
        }

        if (assets && showTrim && showTrim !== prevState.showTrim) {
            this.checkForAsset(assets);
        }
    }

    public componentDidMount() {
        // TODO: refactor this mess
        this.varBtnCheck();
        this.checkForAsset();
        this.checkForPreview();
    }

    private varBtnCheck() {
        if (this.props.config.activeViewer !== VIEWER_TYPES.COMPOSITION_PREVIEW) {
            this.props.updateEditorConfig({
                varsDisabled: false
            });
        } else {
            this.props.updateEditorConfig({
                varsDisabled: true
            });
        }
    }

    // LEGACY
    private checkForPreview() {
        // if this is am old video story, and the preview tab isn't in the viewer config, add it in
        const { story } = this.props;
        const storyType = story ? getStoryType(story) : null;

        if (storyType === SCENE_TYPES.VIDEO) {
            this.props.addViewer({
                type: VIEWER_TYPES.TIMELINE_PREVIEW,
                id: VIEWER_TYPES.TIMELINE_PREVIEW,
                label: VIEWER_LABELS[VIEWER_TYPES.TIMELINE_PREVIEW]
            });
        }
    }

    private getCompAsset(compId, compName) {
        const { compositions } = this.props;
        const comp = compositions[compId];
        if (comp) {
            return {
                ...comp,
                ...{ id: compId, name: compName, type: ASSET_TYPES.VIDEO_COMPOSITION }
            };
        }
        return null;
    }

    private checkForAsset(assets?) {
        const { config } = this.props;

        for (const tab of config.tabs) {
            if (tab.id === config.activeViewer && ASSET_TYPES[tab.type] !== -1) {
                if (tab.type === ASSET_TYPES.VIDEO_COMPOSITION) {
                    const comp = this.getCompAsset(tab.id, tab.label);
                    if (comp) {
                        this.setState({ activeAsset: comp, trimAssetState: comp });
                    }
                } else {
                    let asset;
                    if (assets) {
                        asset = assets.find((i) => i.id === tab.id);
                        if (tab.asset !== asset) {
                            this.setState({
                                activeAsset: asset,
                                trimAssetState: asset
                            });
                        }
                    } else {
                        this.setState({
                            activeAsset: { ...tab.asset },
                            trimAssetState: { ...tab.asset }
                        });
                    }
                }

                return;
            }
        }
        this.setState({ activeAsset: null, trimAssetState: null });
    }

    private updateAssetName(newName: string) {
        const { showTrim, activeAsset, trimAssetState } = this.state;

        if (showTrim) {
            const asset = { ...trimAssetState };
            asset.name = newName;

            this.setState({
                trimAssetState: asset
            });
        } else {
            const newAsset = { ...activeAsset };
            newAsset.name = newName;

            this.props.updateAssetName(api, newAsset.id, newName);
        }
    }

    private removeAssetTag(tag) {
        const { showTrim, activeAsset, trimAssetState } = this.state;
        if (showTrim) {
            const updateTrimAsset = { ...trimAssetState };
            const updateTags = [...updateTrimAsset.tags];
            const index = updateTags.indexOf(tag);
            if (index > -1) {
                updateTags.splice(index, 1);
            }
            updateTrimAsset.tags = updateTags;
            this.setState({ trimAssetState: updateTrimAsset });
        } else {
            this.props.deleteAssetTag(api, activeAsset.id, tag);
        }
    }

    private addAssetTag(tag) {
        const { showTrim, activeAsset, trimAssetState } = this.state;
        if (showTrim) {
            const updateTrimAsset = { ...trimAssetState };
            if (!this.checkTag(updateTrimAsset.tags, tag)) {
                const updateTags = [...updateTrimAsset.tags, tag];
                updateTrimAsset.tags = updateTags;
                this.setState({ trimAssetState: updateTrimAsset });
            }
        } else {
            if (!this.checkTag(activeAsset.tags, tag)) {
                this.props.addAssetTag(api, activeAsset.id, tag);
            }
        }
    }

    private checkTag(assetTags, newTag) {
        return assetTags.includes(newTag);
    }

    private tabChange(i) {
        const { compositions } = this.props;
        this.props.setActiveViewer(i);

        if (i === VIEWER_TYPES.COMPOSITION_PREVIEW) {
            this.props.updateEditorConfig({
                varsDisabled: true,
                varsOpen: false
            });
            this.props.updateTimelineState({
                activeTimelineCut: i
            });
        } else if (compositions[i]) {
            this.props.updateEditorConfig({
                varsDisabled: false,
                expandPanel: false
            });

            this.props.updateTimelineState({
                activeTimelineCut: i
            });
        } else {
            this.props.updateEditorConfig({
                varsDisabled: false
            });
        }
    }

    private checkClose(t) {
        const {
            story: {
                viewer: { tabs }
            },
            changes: { comps, templates }
        } = this.props;

        const closeTab = tabs.find((tab) => tab.id === t);

        if (t === VIEWER_TYPES.COMPOSITION_PREVIEW) {
            this.props.updateEditorConfig({
                varsDisabled: false
            });
        }

        const openPrevious = () => {
            const closeTabIndex = tabs.findIndex((tab) => tab.id === t);
            let tabToOpenIndex = closeTabIndex - 1;

            // If there are no tabs before
            if (tabToOpenIndex < 0) tabToOpenIndex = closeTabIndex + 1;
            const tabToOpen = tabs[tabToOpenIndex];
            if (tabToOpen) {
                this.tabChange(tabToOpen.id);
            }
        };

        if (comps.indexOf(t) !== -1) {
            openConfirmModal({
                onYes: () => {
                    openPrevious();
                    this.props.closeViewer(t);
                    this.props.clearTimelineState(closeTab.id);
                },
                title: settings.confirmComp
            });
        } else if (templates.indexOf(t) !== -1) {
            openConfirmModal({
                onYes: () => {
                    openPrevious();
                    this.props.closeViewer(t);
                },
                title: settings.confirmTemplate
            });
        } else {
            openPrevious();
            this.props.closeViewer(t);
            if (closeTab.type === ASSET_TYPES.VIDEO_COMPOSITION) {
                this.props.clearTimelineState(closeTab.id);
            }
        }
    }

    private inPointChange(inPoint) {
        this.setState({
            inPoint
        });
    }

    private outPointChange(outPoint) {
        this.setState({
            outPoint
        });
    }

    private assetCreater(activeAsset, data) {
        this.props
            .createAsset(activeAsset, data)
            .then(() => this.props.doAssetTableHydration(api, this.props.story.id));
    }

    private handleAssetTrim(asset) {
        const { inPoint, outPoint } = this.state;

        const newAsset = { ...asset };
        const data = {
            in_point: inPoint,
            out_point: outPoint,
            name: newAsset.name,
            tags: newAsset.tags
        };

        this.assetCreater(asset, data);
    }

    private toggleTrimMode() {
        const { showTrim, showAssetDetails } = this.state;
        const details = !showTrim && showAssetDetails ? false : true;
        this.setState(
            {
                showTrim: !showTrim,
                showAssetDetails: false
            },
            () => this.setState({ showAssetDetails: details })
        );
    }

    private onOutsideClickHandler() {
        const { showTrim, showAssetDetails } = this.state;
        const details = !showTrim && showAssetDetails ? false : true;
        this.setState({
            showAssetDetails: details
        });
    }

    public renderTabs() {
        const { story, variables, config, videoFile, varsOpen } = this.props;
        const {
            showAssetDetails,
            activeAsset,
            trimAssetState,
            compSettingsModalOpen,
            showTrim,
            outPoint
        } = this.state;

        const currentTabs = config.tabs.filter((t) => t);

        const storyType = story ? getStoryType(story) : null;
        let compActive = false;
        const tabs = [];

        const detailsButtons = [];

        const btnCancelTrim = (
            <Button
                key='btn-cancel'
                style='subtle'
                tooltip={copy.tooltipCancelTrim}
                onClick={() => this.toggleTrimMode()}>
                {copy.cancel}
            </Button>
        );

        const btnNewTrim = (
            <Button
                key='btn-trim'
                disabled={!outPoint}
                style='bold'
                onClick={() => this.handleAssetTrim(trimAssetState)}
                color='primary'>
                {copy.newTrim}
            </Button>
        );

        const btnStartTrim = (
            <Button
                key='btn-cut'
                style='bold'
                onClick={() => this.setState({ showTrim: true })}
                color='primary'>
                {ICON_CUT}
            </Button>
        );

        if (activeAsset?.type !== ASSET_TYPES.VIDEO) {
            if (detailsButtons.length > 0) {
                detailsButtons.splice(0, detailsButtons.length);
            }
        } else {
            if (showTrim) {
                detailsButtons.push(btnNewTrim, btnCancelTrim);
            } else {
                detailsButtons.push(btnStartTrim);
            }
        }

        for (const tab of currentTabs) {
            const tabConfig: ITab = {
                label: tab.label,
                key: tab.id,
                resize: true
            };

            const assetMenuBtn = (id, asset) => {
                return (
                    <AssetContextMenu
                        asset={asset}
                        showDelete={false}
                        showClose={true}
                        position={'right'}
                        showDetails={true}
                        showSettings={true}
                        onClose={() => this.checkClose(id)}
                        onCloseOthers={() => this.props.closeOtherViewers(id)}
                        onCloseToRight={() => this.props.closeRightViewers(id)}
                        onSettings={() => this.setState({ compSettingsModalOpen: true })}
                        onDetails={() => this.setState({ showAssetDetails: true })}
                        isViewer={true}
                    />
                );
            };

            switch (tab.type) {
                case VIEWER_TYPES.IMAGE_STORY_PREVIEW:
                    tabConfig.content = <StoryPreviewPlayer key={tab.id} />;
                    tabConfig.canClose = false;
                    break;

                case VIEWER_TYPES.COMPOSITION_PREVIEW:
                    tabConfig.content = <CompositionPreviewPlayer key={tab.id} />;
                    tabConfig.canClose = true;
                    break;

                case VIEWER_TYPES.JOB_LOG:
                    tabConfig.content = (
                        <JobLog
                            key={tab.id}
                            jobId={tab.jobId}
                        />
                    );
                    tabConfig.canClose = true;
                    break;

                case VIEWER_TYPES.TIMELINE_PREVIEW:
                    tabConfig.content = (
                        <TimelineVideoPlayer
                            storyType={storyType}
                            key={tab.id}
                            videoFile={videoFile}
                        />
                    );
                    break;

                case ASSET_TYPES.VIDEO_COMPOSITION:
                    tabConfig.content = (
                        <LayeredCompositionPreview
                            muted={false}
                            inView={config.activeViewer === tab.id}
                            key={tab.id}
                            compositionId={tab.id}
                            variables={variables}
                        />
                    );

                    if (tab.label.includes('Asset:')) {
                        tabConfig.label = tab.label.replace('Asset:', 'Composition:').trim();
                    } else {
                        tabConfig.label = tab.label;
                    }

                    tabConfig.menuButtons = assetMenuBtn(
                        tab.id,
                        this.getCompAsset(tab.id, tab.label)
                    );
                    if (tab.id === config.activeViewer) {
                        compActive = true;
                    }
                    break;

                case VIEWER_TYPES.BASE_VIDEO:
                    tabConfig.content = (
                        <VideoPlayer
                            allowManualScale={true}
                            maxWidth={videoFile.width}
                            maxHeight={videoFile.height}
                            key={tab.id}
                            url={tab.url}
                        />
                    );
                    tabConfig.canClose = true;
                    break;

                case VIEWER_TYPES.CUT_PREVIEW:
                    tabConfig.content = (
                        <CutPreviewPlayer
                            key={tab.id}
                            cutId={tab.id}
                            videoFile={videoFile}
                        />
                    );
                    tabConfig.canClose = true;
                    break;

                case VIEWER_TYPES.STORY_PREVIEW:
                    tabConfig.content = <StoryPreviewPlayer key={tab.id} />;
                    tabConfig.canClose = true;
                    break;

                case ASSET_TYPES.VIDEO:
                    if (tab.asset) {
                        const frameRate = tab.asset?.rate;
                        const controls = (
                            <VideoControls
                                trimMode={this.state.showTrim}
                                onInPointChange={(p) => this.inPointChange(p)}
                                onOutPointChange={(p) => this.outPointChange(p)}
                                frameRate={frameRate}
                            />
                        );

                        tabConfig.content = (
                            <VideoPlayer
                                customControls={controls}
                                key={tab.id}
                                url={tab.asset?.edited_url}
                            />
                        );
                        tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    } else {
                        tabConfig.content = <></>;
                    }
                    tabConfig.label = tab.label;
                    break;

                case ASSET_TYPES.HTML:
                    tabConfig.content = (
                        <HTMLAssetEditor
                            key={tab.id}
                            assetId={tab.asset?.id}
                            variables={variables}
                        />
                    );
                    tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    tabConfig.label = tab.label;
                    break;

                case ASSET_TYPES.IMAGE:
                    if (tab.asset) {
                        tabConfig.content = (
                            <ImagePlayer
                                key={tab.id}
                                url={tab.asset?.url}
                            />
                        );
                        tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    } else {
                        tabConfig.content = <></>;
                    }
                    tabConfig.label = tab.label;
                    break;

                case ASSET_TYPES.AUDIO:
                    if (tab.asset) {
                        tabConfig.content = (
                            <AudioPlayer
                                key={tab.id}
                                url={tab.asset?.url}
                            />
                        );
                        tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    } else {
                        tabConfig.content = <></>;
                    }
                    tabConfig.label = tab.label;
                    break;

                case ASSET_TYPES.IMAGE_SEQUENCE:
                    tabConfig.content = <PreviewNotAvailable key={tab.id} />;
                    tabConfig.label = tab.label;
                    tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    break;

                case ASSET_TYPES.TEMPLATE:
                    tabConfig.content = <PreviewNotAvailable key={tab.id} />;
                    tabConfig.label = tab.label;
                    tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    break;

                case ASSET_TYPES.AFTER_EFFECT:
                    tabConfig.content = <PreviewNotAvailable key={tab.id} />;
                    tabConfig.label = tab.label;
                    tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    break;

                case ASSET_TYPES.FONT:
                    tabConfig.content = (
                        <FontAssetPreview
                            key={tab.id}
                            asset={tab.asset}
                        />
                    );
                    tabConfig.label = tab.label;
                    tabConfig.menuButtons = assetMenuBtn(tab.asset.id, tab.asset);
                    break;

                case VIEWER_TYPES.NEW_COMPOSITION:
                    tabConfig.content = <NewCompositionInterface key={tab.id} />;
                    tabConfig.canClose = true;
                    break;
            }

            if (tabConfig.content) {
                tabs.push(tabConfig);
            }
        }

        const getAsset = showTrim ? trimAssetState : activeAsset;

        return (
            <>
                <ImposiumDropdown
                    position='bottomleft'
                    show={showAssetDetails}
                    onOutsideClick={() => this.onOutsideClickHandler()}>
                    <AssetDetails
                        asset={getAsset}
                        columns={2}
                        newAsset={showTrim}
                        headerButtons={detailsButtons}
                        onAddTag={(t) => this.addAssetTag(t)}
                        onRemoveTag={(t) => this.removeAssetTag(t)}
                        onNameChange={(n) => this.updateAssetName(n)}
                    />
                </ImposiumDropdown>
                <Tabs
                    activeTab={config.activeViewer}
                    onChange={(i) => this.tabChange(i)}
                    keepMounted={true}
                    onClose={(i) => this.checkClose(i)}
                    options={tabs}
                    tabPadding={varsOpen ? 0 : 100}
                />
                {compActive && (
                    <Modal
                        onRequestClose={(e) => this.setState({ compSettingsModalOpen: false })}
                        wrapperStyle={{
                            top: HEADER_HEIGHT,
                            left: '0px',
                            width: '100%',
                            height: `calc(100% - ${HEADER_HEIGHT}px)`
                        }}
                        style={{
                            width: '600px',
                            height: '75%',
                            top: '10%',
                            left: 'calc((100% - 600px) / 2)'
                        }}
                        isOpen={compSettingsModalOpen}>
                        <CompositionSettings
                            onSave={() => this.setState({ compSettingsModalOpen: false })}
                        />
                    </Modal>
                )}
            </>
        );
    }

    public render() {
        return <div className='viewer'>{this.renderTabs()}</div>;
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            setActiveViewer,
            closeViewer,
            closeOtherViewers,
            closeRightViewers,
            addViewer,
            updateEditorConfig,
            clearTimelineState,
            updateTimelineState,
            addAssetTag,
            deleteAssetTag,
            updateAssetName,
            createAsset,
            doAssetTableHydration
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        config: state.story.viewer || { ...NEW_EMPTY_DEFAULT_VIEWER },
        changes: state.changes,
        story: state.story,
        compositions: state.compositions.present,
        varsOpen: state.editor.varsOpen,
        errors: state.errors,
        assetList: state.assetList,
        project: state.project,
        timeline: state.timeline[state.project.compositionId]
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
