import {
    COMPOSITION_LAYER_TYPES,
    OVERLAY_POSITION_TYPES,
    RESIZE_TYPES
} from '../../../constants/story';
import store from '../../../redux/store';
import { resolveMediaUrl } from '../../../util/preview';
import { parseInventoryFromVariables } from '../../../util/story';
import { getLayerPosition } from '../../../util/timeline';
import { fitToContainer } from '../../../util/ui';
import LayerSource from './LayerSource';
import * as PIXI from 'pixi.js-legacy';
import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import { ASSET_TYPES } from '@imposium-hub/components';

export default class MediaLayerSource extends LayerSource {
    public componentDidMount(): void {
        super.componentDidMount();

        if (!this.props.inView) {
            return;
        }

        this.createPlaceholder();
        this.checkForNewLayerSource();
    }

    public componentDidUpdate(prevProps, prevState) {
        const {
            layerData,
            layerData: { options },
            compositions,
            assets,
            variables,
            variableMap,
            playing,
            relativeFrame,
            compWidth,
            compHeight,
            interimPosition,
            mounted,
            inView,
            source,
            sourceKey
        } = this.props;
        const { status, sourceWidth, sourceHeight } = this.state;

        super.componentDidUpdate(prevProps, prevState);

        if (source?.type === ASSET_TYPES.VIDEO_COMPOSITION && sourceKey) {
            const { id } = source;
            const patt = new RegExp(`(?:${id})`, 'gm');
            if (sourceKey.match(patt)?.length >= 1) {
                this.setState({ status: LAYER_SOURCE_STATUSES.CIRCULAR_COMP }, () => {
                    this.clear();
                    this.createPlaceholder();
                });
            }
        }

        if (playing && !prevProps.playing) {
            this.play();
        } else if (!playing && prevProps.playing) {
            this.pause();
        }

        if (!inView) {
            return;
        }

        if (this.props.muted !== prevProps.muted) {
            this.muted();
        }

        if (this.props.volume !== prevProps.volume) {
            this.volume();
        }

        if (prevState.sourceWidth !== sourceWidth || prevState.sourceHeight !== sourceHeight) {
            this.resize(true);
        }

        // If the assets, variables, or layerData.type or layerData.options.source has changed, check for a new layer source
        if (
            layerData.type !== prevProps.layerData.type ||
            layerData?.options?.source !== prevProps.layerData?.options?.source ||
            assets !== prevProps.assets ||
            compositions !== prevProps.compositions ||
            variables !== prevProps.variables ||
            inView !== prevProps.inView ||
            variableMap !== prevProps.variableMap
        ) {
            const forceUseLocalAssets = assets !== prevProps.assets;
            this.checkForNewLayerSource(forceUseLocalAssets);
        }

        if (
            status !== prevState.status ||
            options?.source !== prevProps?.layerData?.options?.source
        ) {
            switch (status) {
                case LAYER_SOURCE_STATUSES.ERROR:
                case LAYER_SOURCE_STATUSES.NO_SOURCE:
                case LAYER_SOURCE_STATUSES.NO_PREVIEW:
                case LAYER_SOURCE_STATUSES.LOADING:
                    this.clear();
                    this.createPlaceholder();
                    break;
            }
            // If the interim position changed, just resize the layer
        } else if (
            (interimPosition && interimPosition !== prevProps.interimPosition) ||
            compWidth !== prevProps.compWidth ||
            prevProps.compHeight !== compHeight
        ) {
            this.resize(true);

            // If the keyframe config, effects, position inputs, or relative frame changed, do a full draw
        } else if (
            layerData.keyframes !== prevProps.layerData.keyframes ||
            layerData.effects !== prevProps.layerData.effects ||
            layerData.position_inputs !== prevProps.layerData.position_inputs ||
            relativeFrame !== prevProps.relativeFrame
        ) {
            if (mounted) {
                // Only pass in seek=true if the relativeFrame changed
                void this.draw(relativeFrame !== prevProps.relativeFrame);
            }
        }
    }

    public resize(dispatchDrawEvent = false) {
        const { layerData, interimPosition, relativeFrame, compWidth, compHeight } = this.props;
        const { sourceWidth, sourceHeight } = this.state;

        if (!this.mediaSprite) {
            return;
        }

        this.logInfo(`Resize and Reposition Layer`, true);

        // if (this.mediaSprite && layerData?.position_inputs && source) {
        const { keyframes, position_inputs, position } = layerData;

        // If the layer is a rectangle
        if (position === OVERLAY_POSITION_TYPES.RECT) {
            if (!this.mediaSprite.parent) {
                this.contentSprite.addChild(this.mediaSprite);
            }
            const pos = interimPosition
                ? interimPosition
                : getLayerPosition(
                      position_inputs,
                      keyframes,
                      relativeFrame,
                      compWidth,
                      compHeight
                  );
            const { x, y, width, height } = pos;
            const { vertical_align, horizontal_align, aspect_mode } = layerData.position_inputs;

            if (aspect_mode === 'stretch' || !aspect_mode || !sourceWidth || !sourceHeight) {
                this.clearMask();
                this.mediaSprite.x = x;
                this.mediaSprite.y = y;
                this.mediaSprite.width = width;
                this.mediaSprite.height = height;

                this.createMask(x + width / 2, y + height / 2, width, height);
            } else {
                const scaleMode =
                    aspect_mode === RESIZE_TYPES.CROP
                        ? 'proportionalOutside'
                        : 'proportionalInside';
                const mediaDimensions = fitToContainer(
                    { width, height },
                    { width: sourceWidth, height: sourceHeight },
                    { hAlign: horizontal_align, vAlign: vertical_align, scaleMode }
                );

                this.mediaSprite.x = pos.x + mediaDimensions.left;
                this.mediaSprite.y = pos.y + mediaDimensions.top;
                this.mediaSprite.width = mediaDimensions.width;
                this.mediaSprite.height = mediaDimensions.height;

                this.createMask(
                    pos.x + pos.width / 2,
                    pos.y + pos.height / 2,
                    pos.width,
                    pos.height
                );
            }
        } else {
            if (this.mediaSprite.parent) {
                this.mediaSprite.parent.removeChild(this.mediaSprite);
            }

            // If the layer is a solid, no need for geometry / shader, just use a graphics object
            if (this.props.layerData.type === COMPOSITION_LAYER_TYPES.SOLID) {
                if (this.quadGraphics) {
                    this.contentSprite.removeChild(this.quadGraphics);
                    this.quadGraphics.destroy(true);
                    this.quadGraphics = null;
                }

                const quad = getQuadPosition(position, position_inputs, relativeFrame);
                const coords = [
                    quad.tlX || 0,
                    quad.tlY || 0,
                    quad.trX || 0,
                    quad.trY || 0,
                    quad.brX || 0,
                    quad.brY || 0,
                    quad.blX || 0,
                    quad.blY || 0
                ];
                this.quadGraphics = new PIXI.Graphics();
                const color = layerData?.options?.source?.color;
                this.quadGraphics.beginFill(color);
                this.quadGraphics.drawPolygon(coords);
                this.quadGraphics.endFill();

                this.contentSprite.addChild(this.quadGraphics);
            } else {
                const quadGeometry = getQuadGeometry(position, position_inputs, relativeFrame);
                if (this.quadMesh) {
                    this.contentSprite.removeChild(this.quadMesh);
                    this.quadMesh.destroy(true);
                    this.quadMesh = null;
                }

                // If this is a nested comp, generate a texture from the sprite, if it's not a nested comp, just use the media sprite texture
                const isNestedLayer =
                    this.props.layerData.type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION;

                const sourceW = this.props.source?.width || 0;
                const sourceH = this.props.source?.width || 0;

                const texture = isNestedLayer
                    ? this.props.pixiApp.renderer.generateTexture(this.mediaSprite, {
                          region: new PIXI.Rectangle(0, 0, sourceW, sourceH)
                      })
                    : this.mediaSprite.texture;
                this.quadShader = getQuadShader(texture);
                this.quadMesh = new PIXI.Mesh(
                    quadGeometry,
                    this.quadShader,
                    null,
                    PIXI.DRAW_MODES.TRIANGLES
                );
                this.contentSprite.addChild(this.quadMesh);
            }
        }

        if (dispatchDrawEvent) this.dispatchDrawCompleteEvent();
    }

    public createEmptyMediaSprite(width, height) {
        // If there already is a mediaSprite (media or placeholder), destroy it
        if (this.mediaSprite) {
            this.contentSprite.removeChild(this.mediaSprite);
            this.mediaSprite.destroy(true);
            this.mediaSprite = null;
        }

        const textureRect = new PIXI.Rectangle(0, 0, width, height);
        const tex = new PIXI.Texture(new PIXI.BaseTexture(), textureRect, textureRect);
        this.mediaSprite = new PIXI.Sprite(tex);
        this.mediaSprite.sortableChildren = true;
        this.setInteractivity();
        this.contentSprite.addChild(this.mediaSprite);

        void this.draw(true);
    }

    public createMediaSprite(source) {
        // If there already is a mediaSprite (media or placeholder), destroy it
        if (this.mediaSprite) {
            this.contentSprite.removeChild(this.mediaSprite);
            this.mediaSprite.destroy(true);
            this.mediaSprite = null;
        }

        this.mediaSprite = PIXI.Sprite.from(source);
        this.mediaSprite.sortableChildren = true;
        this.setInteractivity();
        this.contentSprite.addChild(this.mediaSprite);

        void this.draw(true);
    }

    public async doCheckForNewLayerSource(forceUseLocalAssets: boolean = false) {
        const {
            source,
            compositions,
            layerData: { type, options }
        } = this.props;

        const wait = (time) => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    return resolve();
                }, 500);
            });
        };
        const storyId = store.getState().project.storyId;
        const inventory = parseInventoryFromVariables(this.props.variables, this.props.variableMap);

        let src: any;
        const typeFilter =
            type === ASSET_TYPES.VIDEO
                ? `${ASSET_TYPES.VIDEO},${ASSET_TYPES.VIDEO_COMPOSITION}`
                : type;
        this.logInfo(`Check for new layer source`);

        try {
            await wait(500);
            src = await resolveMediaUrl(
                storyId,
                options?.source,
                typeFilter,
                inventory,
                null,
                compositions
            );
        } catch (e) {
            this.logError(`Error resolving media URL`, e);
            this.setState({
                status: LAYER_SOURCE_STATUSES.ERROR
            });
            return;
        }

        // if no source is found, render the error layer
        if (!src) {
            this.logInfo(`No layer source found`);
            this.setState({ status: LAYER_SOURCE_STATUSES.NO_SOURCE });
            if (source) {
                this.props.clearLayerSource();
            }
        } else if (
            (src &&
                src.type === 'video_composition' &&
                src.data &&
                src.id !== this.props.layerData.id &&
                src.data !== source?.data &&
                this.state.status !== LAYER_SOURCE_STATUSES.LOADING) ||
            (src &&
                src.url !== source?.url &&
                this.state.status !== LAYER_SOURCE_STATUSES.LOADING) ||
            (src && src.url && this.state.status === LAYER_SOURCE_STATUSES.NO_SOURCE)
        ) {
            this.logInfo(`New source found: ${src.url || src?.data}`);
            this.props.setLayerSource(src).then(() => {
                if (src.type === 'video_composition') {
                    if (
                        this.state.status !== LAYER_SOURCE_STATUSES.READY &&
                        this.state.status !== LAYER_SOURCE_STATUSES.CIRCULAR_COMP
                    ) {
                        this.createEmptyMediaSprite(src.data.width, src.data.height);
                    }
                    this.setState({
                        status: LAYER_SOURCE_STATUSES.READY
                    });
                } else {
                    this.setState(
                        {
                            status: LAYER_SOURCE_STATUSES.LOADING
                        },
                        () => {
                            this.loadLayerSource().catch((e) => {
                                this.logError(`Error loading layer source`, e);
                                this.setState({
                                    status: LAYER_SOURCE_STATUSES.ERROR
                                });
                            });
                        }
                    );
                }
            });
        }
    }

    public async loadLayerSource(): Promise<void | any> {
        // Overwrite this
        return Promise.resolve();
    }
}

const getQuadPosition = (positionType, positionInputs, relativeFrame) => {
    let tlX;
    let tlY;
    let trX;
    let trY;
    let brX;
    let brY;
    let blX;
    let blY;
    if (positionType === OVERLAY_POSITION_TYPES.MT_QUAD) {
        const coordsArray = positionInputs.coords.split(',');
        let startIndex = relativeFrame * 8;

        // Check if coords for this frame exist, if not, hold on the last frame
        if (startIndex + 7 >= coordsArray.length) {
            startIndex = coordsArray.length - 8;
        }

        tlX = parseFloat(coordsArray[startIndex]);
        tlY = parseFloat(coordsArray[startIndex + 1]);
        trX = parseFloat(coordsArray[startIndex + 2]);
        trY = parseFloat(coordsArray[startIndex + 3]);
        brX = parseFloat(coordsArray[startIndex + 4]);
        brY = parseFloat(coordsArray[startIndex + 5]);
        blX = parseFloat(coordsArray[startIndex + 6]);
        blY = parseFloat(coordsArray[startIndex + 7]);
    } else if (positionType === OVERLAY_POSITION_TYPES.QUAD) {
        const { top_left, top_right, bottom_right, bottom_left } = positionInputs;
        tlX = top_left ? top_left.x : 0;
        tlY = top_left ? top_left.y : 0;
        trX = top_right ? top_right.x : 0;
        trY = top_right ? top_right.y : 0;
        brX = bottom_right ? bottom_right.x : 0;
        brY = bottom_right ? bottom_right.y : 0;
        blX = bottom_left ? bottom_left.x : 0;
        blY = bottom_left ? bottom_left.y : 0;
    }
    return {
        tlX,
        tlY,
        trX,
        trY,
        brX,
        brY,
        blX,
        blY
    };
};

const getQuadGeometry = (positionType, positionInputs, relativeFrame) => {
    const { tlX, tlY, trX, trY, brX, brY, blX, blY } = getQuadPosition(
        positionType,
        positionInputs,
        relativeFrame
    );
    const geometry = new PIXI.Geometry()
        .addAttribute(
            'aVertexPosition', // the attribute name
            [tlX, tlY, trX, trY, brX, brY, blX, blY],
            2
        ) // the size of the attribute
        .addAttribute(
            'aUvs', // the attribute name
            [0, 0, 1, 0, 1, 1, 0, 1],
            2
        ) // the size of the attribute
        .addIndex([0, 1, 2, 0, 2, 3]);

    return geometry;
};

const getQuadShader = (texture) => {
    const shader = PIXI.Shader.from(
        `
            precision mediump float;

            attribute vec2 aVertexPosition;
            attribute vec2 aUvs;

            uniform mat3 translationMatrix;
            uniform mat3 projectionMatrix;

            varying vec2 vUvs;

            void main() {

                vUvs = aUvs;
                gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);

            }`,

        `precision mediump float;

            varying vec2 vUvs;

            uniform sampler2D uSampler2;

            void main() {

                gl_FragColor = texture2D(uSampler2, vUvs );
            }

        `,
        {
            uSampler2: texture
        }
    );

    return shader;
};
