import * as React from 'react';
import { bindActionCreators } from 'redux';
import { IEditor } from '../../../redux/reducers/editor';
import { ICompositionLayer, ILayeredComposition } from '../../../constants/snippets';
import {
    ASSET_TYPES,
    COMPOSITION_LAYER_COLORS,
    COMPOSITION_LAYER_TYPES
} from '../../../constants/story';
import { updateEditorConfig } from '../../../redux/actions/editor';
import { setLayerSource, clearLayerSource } from '../../../redux/actions/layerSources';
import { IProject } from '../../../redux/reducers/project';
import * as PIXI from 'pixi.js-legacy';
import LayerInteractionHandler from './LayerInteractionHandler';
import { connect } from 'react-redux';
import CompPreviewDrop from './CompPreviewDrop';
import { getLayerFromAsset } from '../../../util/story';
import { addLayer, updateLayer } from '../../../redux/actions/compositions';
import { ITimelineState } from '../../../redux/reducers/timeline';
import { FRAME_SEEK_OFFSET } from '../../../constants/timeline';
import ImageSequenceSource from './ImageSequenceSource';
import ImageLayerSource from './ImageLayerSource';
import VideoLayerSource from './VideoLayerSource';
import TextLayerSource from './TextLayerSource';
import TemplateLayerSource from './TemplateLayerSource';
import AudioLayerSource from './AudioLayerSource';
import { circularCompCheck } from '../../../util/timeline';
import SolidLayerSource from './SolidLayerSource';
import { getComputedInterimPosition } from '../../../util/preview';
import { updateTimelineState } from '../../../redux/actions/timeline';
import { PanAndZoom } from './PanAndZoom';
import hotkeys from 'hotkeys-js';

interface ILayeredCompositionPreviewProps {
    project: IProject;
    compositionId: any;
    compositions: ILayeredComposition[];
    editor: IEditor;
    variables: any;
    variableMap?: any;
    width: number;
    height: number;
    loading: boolean;
    active: boolean;
    assets: any;
    layerSourceData: any;
    timeline: ITimelineState;
    updateLayer(compId: string, layer: ICompositionLayer): any;
    updateEditorConfig(conf: any): any;
    updateTimelineState(state: any): any;
    addLayer(compId: string, layer: ICompositionLayer, index?: number): any;
    clearLayerSource(id): void;
    setLayerSource(id, source): void;
    isNested?: boolean;
    compositionData?: any;
    onMount?(canvas): any;
    parentSourceKey?: string;
    activeFrameOverride?: number;
    inView: boolean;
    parentContainer?: any;
    parentPixiApp?: any;
    muted?: boolean;
}

interface ILayeredCompositionPreviewState {
    composition: ILayeredComposition;
    interimPosition: any;
    interimPositionTarget: string;
    pixiInitialized: boolean;
    panMode: boolean;
    mouseOver: boolean;
}

class LayeredCompositionPreview extends React.PureComponent<
    ILayeredCompositionPreviewProps,
    ILayeredCompositionPreviewState
> {
    private evtHandlers = {
        togglePanMode: (e) => this.togglePanMode(e),
        selectLayer: (id, multiSelect) => this.onSelectLayer(id, multiSelect),
        onLayerReady: () => this.onLayerReady(),
        bgClicked: () => this.backgroundClicked(),
        addLayerFromAsset: (asset) => this.addLayerFromAsset(asset)
    };

    private renderCanvas: any;

    private pixiApp: any;

    private bgSprite: any;

    constructor(p) {
        super(p);

        this.state = {
            composition: null,
            interimPosition: null,
            interimPositionTarget: null,
            pixiInitialized: false,
            panMode: false,
            mouseOver: false
        };

        this.renderCanvas = React.createRef();
    }

    public static getDerivedStateFromProps(props) {
        const { compositions, compositionData, compositionId } = props;
        if (compositionData) {
            return { composition: compositionData };
        } else {
            const composition = compositions[compositionId] || null;
            return {
                composition
            };
        }
    }

    private onSelectLayer(id, multiSelect) {
        const { activeMultiSelectLayers, activeTimelineLayer } = this.props.timeline;

        if (!multiSelect) {
            this.props.updateTimelineState({
                activeTimelineLayer: id,
                activeMultiSelectLayers: []
            });
        } else {
            // If we have shift clicked, and we're selecting the last selected layer, clear all selections
            if (activeTimelineLayer === id) {
                this.props.updateTimelineState({
                    activeTimelineLayer: null,
                    activeMultiSelectLayers: []
                });
                return;
            }

            const layerIds = [...activeMultiSelectLayers];
            const idIdx = layerIds.indexOf(id);

            if (layerIds.indexOf(activeTimelineLayer) === -1) {
                layerIds.push(activeTimelineLayer);
            }

            if (idIdx === -1) {
                layerIds.push(id);
            } else {
                layerIds.splice(idIdx, 1);
            }

            // If we're left with one selected layer - convert it to be the active timeline layer
            if (activeMultiSelectLayers.length === 1) {
                this.props.updateTimelineState({
                    activeTimelineLayer: id,
                    activeMultiSelectLayers: []
                });
                return;
            }

            this.props.updateTimelineState({
                activeTimelineLayer: null,
                activeMultiSelectLayers: layerIds
            });
        }
    }

    private togglePanMode(e) {
        e.preventDefault();
        e.stopPropagation();

        const { mouseOver } = this.state;
        const isKeyUp = e.type === 'keyup' ? false : true;
        const panMode = isKeyUp && mouseOver;

        this.setState({ panMode });
    }

    public componentDidMount(): void {
        hotkeys('space', { keyup: true }, this.evtHandlers.togglePanMode);
        if (this.state.composition && !this.state.pixiInitialized && this.props.inView) {
            this.initScene();
            return;
        }
    }

    public componentWillUnmount(): void {
        hotkeys.unbind('space', this.evtHandlers.togglePanMode);
        this.destroyScene();
    }

    private pauseScene(): void {
        this.pixiApp.ticker.stop();
        this.pixiApp.renderer.destroy();
    }

    private unpauseScene(): void {
        const {
            composition: { width, height }
        } = this.state;
        const canvas = this.renderCanvas.current;

        this.pixiApp.renderer = new PIXI.Renderer({
            width,
            height,
            antialias: true,
            backgroundAlpha: 0,
            resolution: 1,
            view: canvas
        });
        this.pixiApp.ticker.start();
    }

    public componentDidUpdate(
        prevProps: Readonly<ILayeredCompositionPreviewProps>,
        prevState: Readonly<ILayeredCompositionPreviewState>,
        snapshot?: any
    ): void {
        const { inView, isNested } = this.props;
        const { pixiInitialized, composition } = this.state;

        if (inView && !pixiInitialized && composition) {
            this.initScene();
            return;
        }

        if (pixiInitialized && inView && !prevProps.inView && !isNested) {
            this.unpauseScene();
        } else if (pixiInitialized && !inView && prevProps.inView && !isNested) {
            this.pauseScene();
        }

        if (composition && pixiInitialized) {
            // Background color changed, re-render the background sprite
            if (composition.background_color !== prevState?.composition.background_color) {
                this.renderBG();
            }

            // The width or height of the comp has changed, resize the renderer, and re-draw the current frame
            if (
                composition.width !== prevState?.composition?.width ||
                composition?.height !== prevState?.composition?.height
            ) {
                if (isNested) {
                    this.pixiApp.renderer.resize(composition.width, composition.height);
                } else {
                    // TODO: handle resizing a nested comp properly
                }
                this.renderBG();
            }
        }
    }

    private destroyScene() {
        if (this.state.pixiInitialized) {
            this.setState(
                {
                    pixiInitialized: false
                },
                () => {
                    if (this.pixiApp) {
                        this.pixiApp.destroy();
                    }
                }
            );
        }
    }

    private initScene() {
        const {
            composition: { width, height }
        } = this.state;
        const { isNested } = this.props;
        const canvas = this.renderCanvas.current;

        if (isNested) {
            console.info(`initialize nested pixi container @ ${width}, ${height}`);
            this.setState({
                pixiInitialized: true
            });
        } else {
            console.info(`initialize pixi scene @ ${width}, ${height}`);

            this.pixiApp = new PIXI.Application({
                width,
                height,
                antialias: true,
                backgroundAlpha: 0,
                resolution: 1,
                view: canvas
            });

            if (this.props.inView) {
                this.pixiApp.ticker.autoStart = true;
            } else {
                this.pixiApp.ticker.autoStart = false;
            }

            // need this to be able to set the z-index of child components
            this.pixiApp.stage.sortableChildren = true;
        }

        this.renderBG();

        this.setState({
            pixiInitialized: true
        });
    }

    private renderBG() {
        const {
            composition: { background_color, width, height }
        } = this.state;

        const { isNested, parentContainer } = this.props;
        const container = isNested ? parentContainer : this.pixiApp.stage;
        if (this.bgSprite) {
            container.removeChild(this.bgSprite);
            this.bgSprite.destroy(true);
        }

        this.bgSprite = new PIXI.Sprite();

        const gfx = new PIXI.Graphics();
        const bg = background_color ? background_color : 0x000000;
        if (background_color === 'transparent') {
            gfx.beginFill(bg, 0.000001);
        } else {
            gfx.beginFill(bg);
        }
        gfx.drawRect(0, 0, width, height);

        this.bgSprite.addChild(gfx);
        container.addChildAt(this.bgSprite, 0);

        this.bgSprite.eventMode = 'dynamic';
        this.bgSprite.on('click', this.evtHandlers.bgClicked);
    }

    private backgroundClicked() {
        if (this.state.panMode) {
            return;
        }
        if (
            this.props.timeline.activeTimelineLayer !== null ||
            this.props.timeline.activeMultiSelectLayers.length > 0
        ) {
            this.props.updateTimelineState({
                activeTimelineLayer: null,
                activeMultiSelectLayers: []
            });
        }
    }

    // Force = don't let it drop the frame
    private drawFrame(force = false) {
        const {
            timeline: {
                activeFrame: editorActiveFrame,
                activeTimelineLayer,
                playing,
                muted,
                volume,
                activeMultiSelectLayers
            },
            variables,
            compositions,
            variableMap,
            assets,
            compositionId,
            parentSourceKey,
            activeFrameOverride,
            layerSourceData,
            inView,
            isNested,
            parentContainer,
            parentPixiApp,
            project: { storyId }
        } = this.props;

        const activeFrame =
            activeFrameOverride !== null && activeFrameOverride !== undefined
                ? activeFrameOverride
                : editorActiveFrame;

        const {
            composition,
            composition: { rate, layers, width, height },
            interimPositionTarget,
            interimPosition
        } = this.state;

        if (composition && this.state.pixiInitialized) {
            const layerSources = [];
            for (let i = 0; i < layers.length; i++) {
                const layer = layers[i];
                const {
                    layerDragOffset,
                    rightDragFrameOffset,
                    leftDragFrameOffset,
                    tempOffsetFrames
                } = this.props.timeline;
                const isActive = layer.id === activeTimelineLayer;
                const isMultiSelect = activeMultiSelectLayers.indexOf(layer.id) !== -1;
                const start =
                    isActive || isMultiSelect
                        ? layer.start_frame + layerDragOffset + leftDragFrameOffset
                        : layer.start_frame;
                const end =
                    isActive || isMultiSelect
                        ? layer.end_frame + layerDragOffset + rightDragFrameOffset
                        : layer.end_frame;
                const mounted = start <= activeFrame && end > activeFrame && inView;
                const layerOffset =
                    tempOffsetFrames !== null ? tempOffsetFrames : layer.offset_frames;
                let relativeFrame = activeFrame - layer.start_frame + layerOffset;
                if (isActive) {
                    relativeFrame -= leftDragFrameOffset;
                    relativeFrame -= layerDragOffset;
                }
                const frameOffset = (1 / rate) * FRAME_SEEK_OFFSET;
                const relativeTime = relativeFrame / rate + frameOffset;

                const sourceKey = getSourceKey(compositionId, layer.id, parentSourceKey);

                const isMatte = layers.map((l) => l.matte_layer_id).includes(layer.id);
                const mute =
                    muted === true || layer.audio_enabled === false || this.props.muted
                        ? true
                        : false;
                const props = {
                    storyId,
                    inView,
                    playing,
                    pixiApp: isNested ? parentPixiApp : this.pixiApp,
                    parent: isNested ? parentContainer : this.pixiApp?.stage,
                    assets,
                    compositions,
                    zIndex: i,
                    layerData: layer,
                    variables,
                    variableMap,
                    frameRate: rate,
                    mounted,
                    relativeFrame,
                    relativeTime,
                    sourceKey,
                    source: layerSourceData[sourceKey],
                    renderMode: false,
                    interimPosition:
                        interimPositionTarget === layer.id
                            ? interimPosition
                            : isMultiSelect
                            ? getComputedInterimPosition(
                                  layers,
                                  interimPosition,
                                  interimPositionTarget,
                                  layer.id,
                                  width,
                                  height,
                                  activeFrame
                              )
                            : null,
                    compWidth: width,
                    compHeight: height,
                    debugMode: false,
                    isMatte,
                    clearLayerSource: () => this.props.clearLayerSource(sourceKey),
                    setLayerSource: (s) => this.props.setLayerSource(sourceKey, s),
                    onClick: this.evtHandlers.selectLayer,
                    color: COMPOSITION_LAYER_COLORS[layer.type],
                    muted: mute,
                    volume
                };

                switch (layer.type) {
                    case COMPOSITION_LAYER_TYPES.VIDEO:
                        layerSources.push(
                            <VideoLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.AUDIO:
                        layerSources.push(
                            <AudioLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.IMAGE:
                        layerSources.push(
                            <ImageLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.HTML:
                    case COMPOSITION_LAYER_TYPES.TEMPLATE:
                        layerSources.push(
                            <TemplateLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION:
                        layerSources.push(
                            <VideoLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE:
                        layerSources.push(
                            <ImageSequenceSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.TEXT:
                        layerSources.push(
                            <TextLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;

                    case COMPOSITION_LAYER_TYPES.SOLID:
                        layerSources.push(
                            <SolidLayerSource
                                key={layer.id}
                                {...props}
                            />
                        );
                        break;
                }
            }

            return layerSources;
        }
    }

    private onUpdateLayerOptions(id, o) {
        const index = this.state.composition.layers.findIndex((l) => l.id === id);
        const newLayer = { ...this.state.composition.layers[index] };
        newLayer.options = o;

        this.props.updateLayer(this.props.compositionId, newLayer);
    }

    private onUpdateLayer(id, l) {
        this.props.updateLayer(this.props.compositionId, l);
    }

    private onLayerReady() {
        // Do something?
    }

    private handleInterimResize(layerId, pos) {
        this.setState({
            interimPosition: pos,
            interimPositionTarget: pos !== null ? layerId : null
        });
    }

    private addLayerFromAsset(asset) {
        const {
            project: { compositionId },
            compositions
        } = this.props;

        if (circularCompCheck(asset)) return null;

        const composition: any = compositions[compositionId];
        const baseVideo: any = composition?.videoFile;
        const width = baseVideo ? baseVideo.width : composition ? composition.width : null;
        const height = baseVideo ? baseVideo.height : composition ? composition.height : null;
        const totalFrames = baseVideo
            ? baseVideo.totalFrames
            : composition
            ? composition.frames
            : null;

        const layer = getLayerFromAsset(asset, width, height, totalFrames);

        this.props.addLayer(compositionId, layer);
        this.props.updateTimelineState({ activeTimelineLayer: layer.id, activeKeyframes: [] });
    }

    private renderInteractionHandlers() {
        const { composition, interimPosition, interimPositionTarget } = this.state;
        const {
            isNested,
            timeline: {
                activeTimelineLayer,
                activeMultiSelectLayers,
                activeFrame: editorActiveFrame
            },
            activeFrameOverride,
            compositionId,
            timeline: { layerDragOffset, leftDragFrameOffset },
            inView
        } = this.props;

        const { panMode } = this.state;

        const activeFrame =
            activeFrameOverride !== null && activeFrameOverride !== undefined
                ? activeFrameOverride
                : editorActiveFrame;

        const {
            composition: { width, height }
        } = this.state;

        const getInteractionHandler = (layerId, isMultiSelect = false) => {
            const layerData = composition.layers.find((l) => {
                return l.id === layerId;
            });

            if (layerData && layerData.type !== ASSET_TYPES.AUDIO && inView) {
                const startFrame = layerData.start_frame + layerDragOffset + leftDragFrameOffset;

                if (
                    !isNested &&
                    startFrame <= editorActiveFrame &&
                    layerData.end_frame > editorActiveFrame &&
                    layerData.video_enabled
                ) {
                    return (
                        <LayerInteractionHandler
                            disabled={panMode}
                            compositionId={compositionId}
                            key={`handlers-${layerId}`}
                            compWidth={composition.width}
                            compHeight={composition.height}
                            activeFrame={activeFrame}
                            interimPosition={
                                layerData.id === interimPositionTarget
                                    ? interimPosition
                                    : getComputedInterimPosition(
                                          composition.layers,
                                          interimPosition,
                                          interimPositionTarget,
                                          layerData.id,
                                          width,
                                          height,
                                          editorActiveFrame
                                      )
                            }
                            layer={layerData}
                            onClick={this.evtHandlers.selectLayer}
                            onUpdateInterim={(pos) => this.handleInterimResize(layerId, pos)}
                            onUpdateOptions={(o) => this.onUpdateLayerOptions(layerId, o)}
                            onUpdate={(l) => this.onUpdateLayer(layerId, l)}
                        />
                    );
                }
            }
        };
        if (activeTimelineLayer) {
            return [getInteractionHandler(activeTimelineLayer)];
        } else {
            const handlers = [];
            for (const id of activeMultiSelectLayers) {
                handlers.push(getInteractionHandler(id, true));
            }
            return handlers;
        }
    }

    public render() {
        const { composition } = this.state;
        const { active, width, height, timeline } = this.props;

        if (!composition || !timeline) {
            return null;
        }

        const previewStyle = {
            display: active ? 'block' : 'none'
        };

        const canvasStyle: any = {
            width: `${composition.width}px`,
            height: `${composition.height}px`
        };

        const interactionHandlers = this.renderInteractionHandlers();

        return (
            <div
                id='video-composition-preview'
                style={previewStyle}
                onMouseOver={(e) => this.setState({ mouseOver: e.type === 'mouseover' })}
                onMouseOut={(e) => this.setState({ mouseOver: e.type === 'mouseover' })}>
                <CompPreviewDrop onAssetDrop={this.evtHandlers.addLayerFromAsset}>
                    <PanAndZoom
                        panEnabled={this.state.panMode}
                        frameWidth={width}
                        frameHeight={height}
                        onBgClicked={this.evtHandlers.bgClicked}
                        contentWidth={composition.width}
                        contentHeight={composition.height}>
                        <div
                            className='preview-wrapper'
                            style={canvasStyle}>
                            {this.props.inView && (
                                <canvas
                                    ref={this.renderCanvas}
                                    id='preview-canvas'
                                    width={composition.width}
                                    height={composition.height}
                                />
                            )}
                            {interactionHandlers}
                            {this.drawFrame()}
                        </div>
                    </PanAndZoom>
                </CompPreviewDrop>
            </div>
        );
    }
}

export const getSourceKey = (compId, layerId, parentKey = null) => {
    const layerSourceKey = `comp:${compId}-layer:${layerId}`;
    const sourceKey = parentKey ? `${parentKey}-${layerSourceKey}` : layerSourceKey;
    return sourceKey;
};

const mapStateToProps = (state) => {
    return {
        editor: state.editor,
        compositions: state.compositions.present,
        assets: state.assetList.assets,
        project: state.project,
        layerSourceData: state.layerSources,
        timeline: state.timeline[state.project.compositionId]
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig,
            updateTimelineState,
            updateLayer,
            addLayer,
            clearLayerSource,
            setLayerSource
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    LayeredCompositionPreview
);
