import * as React from 'react';
import {
    TextField,
    CheckboxField,
    ButtonGroupField,
    FieldWrapper,
    Button,
    HRule,
    Tabs,
    SelectField,
    doAssetTableHydration
} from '@imposium-hub/components';
import { MODERATION_STATUS_OPTIONS, MODERATION_STATUS, SCENE_TYPES } from '../constants/story';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    editStory,
    updateScene,
    addScene,
    deleteScene,
    addViewer,
    updateInventory,
    deleteInventory,
    deleteStory,
    clearStoryCache,
    copyStory
} from '../redux/actions/story';
import { updateEditorConfig } from '../redux/actions/editor';
import { logNotification, logError } from '../util/notifications';
import { fields as copy, header } from '../constants/copy';
import { disableForEncodingSettings, getFirstAct, getStoryType, newAPIScene } from '../util/story';
import EncodingSettingList from './EncodingSettingList';
import ImageOutputSettings from './ImageOutputSettings';
import VideoStoryImageOutputSettings from './VideoStoryImageOutputSettings';
import { IStory, NEW_METADATA } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import type { IChanges } from '../redux/reducers/changes';
import S3BucketFields from './S3BucketFields';
import { saveStory, mapQueueToStory } from '../redux/actions/story';
import { ICON_COPY, ICON_PLUS, ICON_SAVE, ICON_TRASH } from '../constants/icons';
import APICallbackConfig from './APICallbackConfig';
import SuperUserOnly from './SuperUserOnly';
import IntegrationDetails from './IntegrationDetails';
import PaneErrorBoundry from './errors/PaneErrorBoundry';
import { POST_SETTINGS_ACTIONS, PROJECT_SETTINGS_TABS } from '../constants/editor';
import { VIEWER_TYPES } from '../constants/viewer';
import { MetadataConfig } from './MetadataConfig';
import { replaceRoute, pushRoute } from '../util/routing';
import VideoStoryAudioOutputSettings from './VideoStoryAudioOutputSettings';
import { api } from '../constants/app';
import { openConfirmModal } from '../util/ui';

interface IProjectSettingsProps {
    story: IStory;
    project: IProject;
    changes: IChanges;
    editor: IEditor;
    editStory(s): void;
    saveStory(): any;
    updateScene(d): void;
    addScene(d): void;
    updateInventory(d): void;
    deleteInventory(d): void;
    deleteScene(d): void;
    addViewer(c): void;
    updateEditorConfig(c): void;
    queues: any[];
    mapQueueToStory: (queueId: string, storyId: string, distributed: boolean) => any;
    deleteStory(i: string): void;
    clearStoryCache(i: string): any;
    copyStory(i, n?): any;
    doAssetTableHydration: (apiInstance: any, storyId: string, onErr?: () => any) => any;
}

interface IProjectSettingsState {
    unsaved: boolean;
    storyCache: IStory;
    saving: boolean;
    isNameDup: boolean;
    disableSave: boolean;
}

class ProjectSettings extends React.PureComponent<IProjectSettingsProps, IProjectSettingsState> {
    constructor(props) {
        super(props);

        this.state = {
            unsaved: false,
            storyCache: this.props.story,
            saving: false,
            isNameDup: false,
            disableSave: true
        };
    }

    public componentDidUpdate(): void {
        const { unsaved, isNameDup } = this.state;
        const disableSave = !unsaved || disableForEncodingSettings(this.getScene()) || isNameDup;
        this.setState({ disableSave });
    }

    public storyInputChanged(key, value) {
        const fields = {
            [key]: value
        };

        this.setState({
            unsaved: true,
            storyCache: { ...this.state.storyCache, ...fields }
        });
    }

    public sceneInputChanged(key, value) {
        const scene = { ...this.getScene() };
        scene[key] = value;

        this.updateScene(scene);
    }

    public sceneDataInputChanged(key, value) {
        const scene = { ...this.getScene() };
        const data = { ...scene.sceneData };
        data[key] = value;
        scene.sceneData = data;

        const {
            sceneData: { encodingSettings, imageOutputSettings, audioOutputSettings }
        } = scene;

        const imgOrAudio =
            (imageOutputSettings && imageOutputSettings.length > 0) ||
            (audioOutputSettings && audioOutputSettings.length > 0);

        if (encodingSettings.length > 0 && imgOrAudio) {
            scene.skipVideoOutput = false;
        } else if (encodingSettings.length === 0 && imgOrAudio) {
            scene.skipVideoOutput = true;
        }

        this.updateScene(scene);
    }

    private updateScene(scene) {
        const {
            project: { actId, sceneId }
        } = this.props;

        const updateScenes = { scenes: { [sceneId]: { ...scene } } };
        const currentActs = { ...this.state.storyCache.acts[actId] };
        const updateActs = {
            acts: { [actId]: { ...currentActs, ...updateScenes } }
        };
        const storyCache = { ...this.state.storyCache, ...updateActs };

        this.setState({
            unsaved: true,
            storyCache
        });
    }

    private getScene() {
        const {
            project: { actId, sceneId }
        } = this.props;
        const { storyCache } = this.state;
        const act = actId ? storyCache.acts[actId] : getFirstAct(storyCache);
        return act.scenes[sceneId];
    }

    private renderCallbackScene(key) {
        const {
            project: { actId }
        } = this.props;
        const { storyCache: story } = this.state;
        const sceneId = story[key];

        if (sceneId) {
            const scene = story.acts[actId].scenes[sceneId];
            return (
                <APICallbackConfig
                    config={scene}
                    onUpdate={(d, s) => this.updateCallback(d, s)}
                    onDelete={(id) => this.unsetCallback(key, id)}
                />
            );
        } else {
            if (key === 'moderationApprovedScene') {
                return (
                    <Button
                        style='subtle'
                        color='primary'
                        onClick={() => this.addCallback(key)}>
                        {ICON_PLUS}
                    </Button>
                );
            } else {
                return (
                    <Button
                        onClick={() => this.addCallback(key)}
                        style='subtle'
                        color='primary'>
                        {ICON_PLUS}
                    </Button>
                );
            }
        }
    }

    private updateCallback(scene, unsaved) {
        const {
            project: { actId }
        } = this.props;

        const { storyCache } = this.state;
        const updateScenes = { ...storyCache.acts[actId].scenes, ...{ [scene.id]: scene } };
        const updateActs = {
            ...storyCache.acts[actId],
            ...{ scenes: updateScenes }
        };
        const updateStoryCache = { ...storyCache, ...{ acts: { [actId]: updateActs } } };
        this.setState({ unsaved, storyCache: updateStoryCache });
    }

    private unsetCallback(key, sceneId) {
        const {
            project: { actId }
        } = this.props;
        const { storyCache: story } = this.state;
        const storyConfig = {
            [key]: ''
        };

        const act = story.acts[actId];
        // Loop through the inventory on the story, if any exist for the callback we're deleting, ask if we should delete them
        for (const invKey in act.inventory) {
            if (act.inventory.hasOwnProperty(invKey)) {
                const inv = act.inventory[invKey];
                if (inv.integration && inv.integration === sceneId) {
                    openConfirmModal({
                        onYes: () =>
                            this.props.deleteInventory({
                                actId,
                                inventoryId: inv.id
                            }),
                        title: copy.project.confirmInventoryDelete
                    });
                }
            }
        }

        const storyCache = { ...story, ...storyConfig };
        const currentActs = { ...storyCache.acts[actId] };
        const currentScenes = { ...currentActs.scenes };
        delete currentScenes[sceneId];
        const updateActs = { ...storyCache.acts[actId], ...{ scenes: currentScenes } };
        const updateStoryCache = { ...storyCache, ...{ acts: { [actId]: updateActs } } };
        this.setState({ unsaved: true, storyCache: updateStoryCache });
    }

    private addCallback(key) {
        const {
            project: { actId }
        } = this.props;
        const videoSceneId = this.props.project.sceneId;
        const scene = newAPIScene(videoSceneId);

        const storyConfig = {
            [key]: scene.id
        };

        const storyCache = { ...this.state.storyCache, ...storyConfig };
        const updateScenes = { ...storyCache.acts[actId].scenes, ...{ [scene.id]: scene } };
        const updateActs = { ...storyCache.acts[actId], ...{ scenes: updateScenes } };
        const updateStoryCache = { ...storyCache, ...{ acts: { [actId]: updateActs } } };
        this.setState({ storyCache: updateStoryCache });
    }

    private changeActiveTab(i) {
        this.props.updateEditorConfig({
            activeSettingsTab: i
        });
    }

    private saveStoryAs() {
        const {
            project: { storyId },
            story: { name }
        } = this.props;

        const copyStoryName = prompt('Save As:', `Copy of ${name}`);
        const type = typeof copyStoryName;

        if (type === 'string' && copyStoryName !== '') {
            this.setState({ saving: true });
            this.props.copyStory(storyId, copyStoryName).then((data) => {
                pushRoute(`/${data.story_id}`);
                this.closeSettingsModel();
            });
        }
    }

    private clearAssetCacheClicked() {
        const {
            project: { storyId }
        } = this.props;

        const onYes = () => {
            this.props
                .clearStoryCache(storyId)
                .then(() => this.props.doAssetTableHydration(api, storyId));
            this.closeSettingsModel();
        };

        openConfirmModal({
            onYes: () => onYes(),
            title: header.confirmClearCache
        });
    }

    private deleteStoryClicked() {
        const {
            project: { storyId },
            story: { name }
        } = this.props;

        const onYes = () => {
            replaceRoute('/');
            this.props.deleteStory(storyId);
            this.closeSettingsModel();
        };

        openConfirmModal({
            onYes: () => onYes(),
            title: header.confirmDelete.replace('[storyName]', name)
        });
    }

    private closeSettingsModel() {
        this.props.updateEditorConfig({
            settingsModalOpen: false
        });
    }

    private save() {
        const {
            story,
            editor: { postSettingsAction }
        } = this.props;

        this.setState(
            {
                unsaved: false
            },
            () => {
                this.props.editStory(this.state.storyCache);
                this.props.saveStory();

                const editorConfig: any = {
                    settingsModalOpen: false,
                    activeSettingsTab: PROJECT_SETTINGS_TABS.PROJECT,
                    postSettingsAction: null
                };

                if (postSettingsAction) {
                    if (postSettingsAction === POST_SETTINGS_ACTIONS.PUBLISH) {
                        editorConfig.publishWizardOpen = true;
                    } else if (postSettingsAction === POST_SETTINGS_ACTIONS.PREVIEW) {
                        const type = getStoryType(story);
                        if (type === SCENE_TYPES.COMPOSITION) {
                            this.props.addViewer({
                                type: VIEWER_TYPES.COMPOSITION_PREVIEW,
                                label: `Composition Preview`,
                                id: VIEWER_TYPES.COMPOSITION_PREVIEW
                            });
                        } else {
                            this.props.addViewer({
                                type: VIEWER_TYPES.STORY_PREVIEW,
                                label: `Story Preview`,
                                id: VIEWER_TYPES.STORY_PREVIEW
                            });
                        }
                    }
                }

                this.props.updateEditorConfig(editorConfig);
            }
        );
    }

    private renderAwaitApprovalCheckbox() {
        const {
            storyCache: { moderationType },
            storyCache
        } = this.state;

        if (moderationType === MODERATION_STATUS.PRE) {
            return (
                <CheckboxField
                    label={copy.project.awaitApproval}
                    value={storyCache['moderationAwaitApproval']}
                    tooltip={copy.project.tooltipAwaitApproval}
                    width='50%'
                    onChange={(v) => this.storyInputChanged('moderationAwaitApproval', v)}
                />
            );
        }

        if (moderationType === MODERATION_STATUS.POST) {
            return (
                <CheckboxField
                    label={copy.project.lockApproval}
                    value={storyCache['moderationLockApproval']}
                    tooltip={copy.project.tooltipLockApproval}
                    width='50%'
                    onChange={(v) => this.storyInputChanged('moderationLockApproval', v)}
                />
            );
        }
    }

    private disableStory(v) {
        const {
            storyCache: { disabled }
        } = this.state;
        if (!disabled) {
            openConfirmModal({
                onYes: () => this.storyInputChanged('disabled', v),
                title: copy.project.disablePrompt
            });
        } else {
            this.storyInputChanged('disabled', v);
        }
    }

    private enableVariableAssets(v) {
        const {
            story: { variableAssets }
        } = this.props;

        if (!variableAssets) {
            openConfirmModal({
                onYes: () => this.storyInputChanged('variableAssets', v),
                title: copy.project.varAssetsPrompt
            });
        } else {
            this.storyInputChanged('variableAssets', v);
        }
    }

    private assignQueue(queueId: string, storyId: string): void {
        const queueName = this.props.queues.find((q) => q.queueId === queueId).displayName;
        const activeQueue: any = this.props.queues.find((q) => q.queueId === queueId);
        const distributed: boolean = activeQueue ? activeQueue.distributed : false;

        const onYes = () => {
            if (queueId) {
                this.props.mapQueueToStory(queueId, storyId, distributed).catch((e) => {
                    console.error(e);
                });
            }
        };

        openConfirmModal({
            onYes: () => onYes(),
            title: copy.queue.queueConfirm.replace('[QUEUE_NAME]', queueName)
        });
    }

    private getQueueSelect() {
        const {
            queues,
            story: { queueId: queue_id, id: story_id }
        } = this.props;

        const options = [];

        queues.map((queue: any, i: number) => {
            const { queueId, displayName } = queue;

            options.push({ label: displayName, value: queueId });
        });

        return (
            <SelectField
                label={copy.queue.queueTitle}
                width='100%'
                onChange={(v) => this.assignQueue(v, story_id)}
                value={queue_id}
                options={options}
            />
        );
    }

    public render() {
        const {
            editor: { activeSettingsTab, fromCrM },
            project: projectProps
        } = this.props;

        const { saving, disableSave, storyCache } = this.state;

        if (storyCache) {
            const scene = this.getScene();
            const { skipVideoOutput } = scene;
            const imageStory = scene.type === SCENE_TYPES.IMAGE;

            const project = (
                <div
                    key='project'
                    className='settings-tab'>
                    <TextField
                        label={copy.global.name}
                        value={storyCache['name']}
                        tooltip={copy.project.tooltipName}
                        onChange={(v) => this.storyInputChanged('name', v)}
                    />
                    <TextField
                        label={copy.project.story}
                        value={storyCache['id']}
                        tooltip={copy.project.tooltipStoryId}
                        showCopy={true}
                        onNotification={(n) => logNotification(n)}
                        onError={(e) => logError(e)}
                        readOnly={true}
                    />
                    {!fromCrM && (
                        <CheckboxField
                            label={copy.project.disable}
                            value={storyCache['disabled']}
                            tooltip={copy.project.tooltipDisable}
                            onChange={(v) => this.disableStory(v)}
                        />
                    )}
                    <CheckboxField
                        label={copy.project.variableAssets}
                        value={storyCache['variableAssets']}
                        tooltip={copy.project.tooltipVarAssets}
                        onChange={(v) => this.enableVariableAssets(v)}
                    />
                    <div className='buttons'>
                        {!fromCrM && (
                            <Button
                                loading={saving}
                                color='primary'
                                onClick={() => this.saveStoryAs()}>
                                {ICON_COPY}&nbsp;{header.btnSaveAs}
                            </Button>
                        )}
                        <Button
                            onClick={() => this.clearAssetCacheClicked()}
                            tooltip={copy.project.tooltipCacheClear}
                            color='primary'>
                            {ICON_TRASH}&nbsp;{header.cacheClear}
                        </Button>
                        {!fromCrM && (
                            <Button
                                onClick={() => this.deleteStoryClicked()}
                                color='danger'>
                                {ICON_TRASH}&nbsp;{header.delete}
                            </Button>
                        )}
                    </div>
                    <SuperUserOnly>
                        {this.getQueueSelect()}
                        <S3BucketFields
                            label={copy.project.uploads}
                            value={storyCache['uploadsBucket']}
                            tooltip={copy.project.tooltipUploads}
                            bucketReadOnly={false}
                            createCloudFront={false}
                            onChange={(v) => this.storyInputChanged('uploadsBucket', v)}
                        />
                        <S3BucketFields
                            label={copy.project.rendered}
                            value={storyCache['renderedBucket']}
                            tooltip={copy.project.tooltipRenders}
                            bucketReadOnly={false}
                            createCloudFront={true}
                            onChange={(v) => this.storyInputChanged('renderedBucket', v)}
                        />
                        <CheckboxField
                            label={copy.project.discard}
                            value={storyCache['discardUgc']}
                            tooltip={copy.project.tooltipDiscard}
                            info={copy.project.discardInfo}
                            onChange={(v) => this.storyInputChanged('discardUgc', v)}
                        />
                    </SuperUserOnly>
                </div>
            );

            const moderation = (
                <div
                    key='moderation'
                    className='settings-tab'>
                    <ButtonGroupField
                        label={copy.moderation.initialStatus}
                        tooltip={copy.project.tooltipInitial}
                        value={storyCache['moderationType']}
                        options={MODERATION_STATUS_OPTIONS}
                        onChange={(v) => this.storyInputChanged('moderationType', v)}
                    />
                    <CheckboxField
                        label={copy.moderation.rejected}
                        tooltip={copy.project.tooltipRelocate}
                        width='50%'
                        value={storyCache['moderationRenameOnReject']}
                        onChange={(v) => this.storyInputChanged('moderationRenameOnReject', v)}
                    />
                    {this.renderAwaitApprovalCheckbox()}
                    <FieldWrapper
                        label={copy.moderation.onApprove}
                        tooltip={copy.project.tooltipApprove}>
                        {this.renderCallbackScene('moderationApprovedScene')}
                    </FieldWrapper>
                    <FieldWrapper
                        label={copy.moderation.onReject}
                        tooltip={copy.project.tooltipRejected}>
                        {this.renderCallbackScene('moderationRejectedScene')}
                    </FieldWrapper>
                    <FieldWrapper
                        label={copy.moderation.onError}
                        tooltip={copy.project.tooltipError}>
                        {this.renderCallbackScene('errorScene')}
                    </FieldWrapper>
                </div>
            );

            const video = (
                <div
                    key='video'
                    className='settings-tab'>
                    <EncodingSettingList
                        fromCrM={fromCrM}
                        config={scene.sceneData.encodingSettings}
                        onChange={(v) => this.sceneDataInputChanged('encodingSettings', v)}
                        isNameDup={(b) => this.setState({ isNameDup: b })}
                    />

                    {!skipVideoOutput && !fromCrM && (
                        <CheckboxField
                            label={copy.encoding.playlist}
                            tooltip={copy.encoding.tooltipHLS}
                            value={scene['exportAsPlaylist']}
                            onChange={(v) => this.sceneInputChanged('exportAsPlaylist', v)}
                        />
                    )}
                </div>
            );

            const image = (
                <div
                    key='image'
                    className='settings-tab'>
                    <VideoStoryImageOutputSettings
                        story={storyCache}
                        project={projectProps}
                        config={scene.sceneData.imageOutputSettings}
                        onChange={(v) => this.sceneDataInputChanged('imageOutputSettings', v)}
                        isNameDup={(b) => this.setState({ isNameDup: b })}
                    />
                </div>
            );

            const audio = (
                <div
                    key='audio'
                    className='settings-tab'>
                    <VideoStoryAudioOutputSettings
                        story={storyCache}
                        project={projectProps}
                        config={scene.sceneData.audioOutputSettings}
                        onChange={(v) => this.sceneDataInputChanged('audioOutputSettings', v)}
                        isNameDup={(b) => this.setState({ isNameDup: b })}
                    />
                </div>
            );

            const integration = (
                <div
                    key='integration'
                    className='settings-tab'>
                    <IntegrationDetails />
                </div>
            );

            const player = (
                <MetadataConfig
                    config={storyCache.metadata || { ...NEW_METADATA }}
                    onChange={(v) => this.storyInputChanged('metadata', v)}
                />
            );
            const confirmSettings = (
                <div
                    key='confirm-settings'
                    className='settings-tab'>
                    <h2>{copy.confirm.videoTitle}</h2>
                    <HRule />
                    <EncodingSettingList
                        config={scene.sceneData.encodingSettings}
                        onChange={(v) => this.sceneDataInputChanged('encodingSettings', v)}
                    />
                    {/* {skipVideoCheckbox} */}
                    <HRule />
                    <br />
                    <h2>{copy.confirm.imageTitle}</h2>
                    <HRule />
                    <VideoStoryImageOutputSettings
                        story={storyCache}
                        project={projectProps}
                        config={scene.sceneData.imageOutputSettings}
                        onChange={(v) => this.sceneDataInputChanged('imageOutputSettings', v)}
                    />
                    <HRule />
                </div>
            );

            let tabs;

            if (imageStory) {
                const imageOutput = (
                    <div
                        key='image-output'
                        className='settings-tab'>
                        <ImageOutputSettings
                            config={scene.sceneData.imageOutputSettings}
                            onChange={(v) => this.sceneDataInputChanged('imageOutputSettings', v)}
                        />
                    </div>
                );

                tabs = [
                    {
                        label: copy.settings.project,
                        key: PROJECT_SETTINGS_TABS.PROJECT,
                        content: project
                    },
                    {
                        label: copy.settings.image,
                        key: PROJECT_SETTINGS_TABS.IMAGE_OUTPUT,
                        content: imageOutput
                    },
                    {
                        label: copy.settings.moderation,
                        key: PROJECT_SETTINGS_TABS.MODERATION,
                        content: moderation
                    },
                    {
                        label: copy.settings.integration,
                        key: PROJECT_SETTINGS_TABS.INTEGRATION,
                        content: integration
                    }
                ];
            } else {
                // if we're just confirming output settings, only show that tab
                if (activeSettingsTab === PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS) {
                    tabs = [
                        {
                            label: copy.settings.confirm,
                            key: PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS,
                            content: confirmSettings
                        }
                    ];
                } else if (fromCrM) {
                    tabs = [
                        {
                            label: copy.settings.project,
                            key: PROJECT_SETTINGS_TABS.PROJECT,
                            content: project
                        },
                        {
                            label: copy.settings.video,
                            key: PROJECT_SETTINGS_TABS.VIDEO,
                            content: video
                        }
                    ];
                } else {
                    tabs = [
                        {
                            label: copy.settings.project,
                            key: PROJECT_SETTINGS_TABS.PROJECT,
                            content: project
                        },
                        {
                            label: copy.settings.moderation,
                            key: PROJECT_SETTINGS_TABS.MODERATION,
                            content: moderation
                        },
                        {
                            label: copy.settings.player,
                            key: PROJECT_SETTINGS_TABS.PLAYER,
                            content: player
                        },
                        {
                            label: copy.settings.video,
                            key: PROJECT_SETTINGS_TABS.VIDEO,
                            content: video
                        },
                        {
                            label: copy.settings.image,
                            key: PROJECT_SETTINGS_TABS.IMAGE,
                            content: image
                        },
                        {
                            label: copy.settings.audio,
                            key: PROJECT_SETTINGS_TABS.AUDIO,
                            content: audio
                        },
                        {
                            label: copy.settings.integration,
                            key: PROJECT_SETTINGS_TABS.INTEGRATION,
                            content: integration
                        }
                    ];
                }
            }

            const btnSave = (
                <Button
                    disabled={disableSave}
                    customStyles={{
                        position: 'absolute',
                        bottom: '3px',
                        left: '50%',
                        marginLeft: '-47px'
                    }}
                    onClick={() => this.save()}
                    color='primary'>
                    {ICON_SAVE}&nbsp;Save Changes
                </Button>
            );

            return (
                <div className='project-settings'>
                    <PaneErrorBoundry>
                        <Tabs
                            activeTab={activeSettingsTab}
                            onChange={(i) => this.changeActiveTab(i)}
                            options={tabs}
                        />
                        ;{btnSave}
                    </PaneErrorBoundry>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            editStory,
            updateScene,
            addScene,
            updateInventory,
            deleteInventory,
            deleteScene,
            saveStory,
            updateEditorConfig,
            addViewer,
            mapQueueToStory,
            deleteStory,
            clearStoryCache,
            copyStory,
            doAssetTableHydration
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        editor: state.editor,
        project: state.project,
        changes: state.changes,
        queues: state.queues
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
