import actions from '../actions/story';
import { assetActions } from '@imposium-hub/components';
import { createReducer } from '@reduxjs/toolkit';
import { generateUUID, getTabIndex } from '../../util/story';
import { NEW_EMPTY_DEFAULT_VIEWER } from '../../constants/snippets';
import { ASSET_TYPES } from '../../constants/story';

const initialState: any = null;

const story = createReducer(initialState, {
    [actions.SET_STORY]: (state, action: any) => {
        return { ...action.data };
    },

    [actions.EDIT_STORY]: (state, action: any) => {
        return { ...state, ...action.config };
    },

    [actions.UPDATE_CUT]: (state, action: any) => {
        const cuts = [
            ...state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts
        ];
        cuts[action.config.cutIndex] = action.config.cut;
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts = cuts;
    },

    [actions.ADD_CUT]: (state, action: any) => {
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts.push(
            action.config.cut
        );
    },

    [actions.DELETE_CUT]: (state, action: any) => {
        const newCuts = [
            ...state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts
        ];
        newCuts.splice(action.config.cutIndex, 1);
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts = newCuts;
    },

    [actions.ADD_AUDIO_OVERLAY]: (state, action: any) => {
        const overlays =
            state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays ||
            [];
        const newOverlays = [...overlays];
        newOverlays.push(action.config.overlay);

        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays =
            newOverlays;
    },

    [actions.UPDATE_AUDIO_OVERLAY]: (state, action: any) => {
        const overlays = [
            ...state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays
        ];
        overlays[action.config.index] = action.config.overlay;
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays =
            overlays;
    },

    [actions.DELETE_AUDIO_OVERLAY]: (state, action: any) => {
        const newOverlays = [
            ...state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays
        ];
        newOverlays.splice(action.config.index, 1);
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays =
            newOverlays;
    },

    [actions.DUPLICATE_AUDIO_OVERLAY]: (state, action: any) => {
        const newOverlays = [
            ...state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays
        ];
        const newOverlay = { ...newOverlays[action.config.index] };
        newOverlay.id = generateUUID();
        newOverlay.name = `Copy of ${newOverlay.name}`;
        newOverlays.push(newOverlay);
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.audioOverlays =
            newOverlays;
    },

    [actions.UPDATE_SCENE]: (state, action: any) => {
        state.acts[action.config.actId].scenes[action.config.sceneId] = action.config.scene;
    },

    [actions.ADD_SCENE]: (state, action: any) => {
        state.acts[action.config.actId].scenes[action.config.sceneId] = action.config.scene;
    },

    [actions.DELETE_SCENE]: (state, action: any) => {
        delete state.acts[action.config.actId].scenes[action.config.sceneId];
    },

    [actions.UPDATE_INVENTORY]: (state, action: any) => {
        let inventoryItem = action.config.inventoryItem;

        if (inventoryItem.type !== 'video') {
            delete inventoryItem.versions;
        } else {
            inventoryItem = inventoryItem;
        }

        const oldInv = { ...state.acts[action.config.actId].inventory };
        oldInv[action.config.inventoryId] = inventoryItem;
        state.acts[action.config.actId].inventory = oldInv;
    },

    [actions.UPDATE_MULTIPLE_INVENTORY]: (state, action: any) => {
        const oldInv = { ...state.acts[action.actId].inventory };
        for (const inventoryItem of action.items) {
            if (inventoryItem.type !== 'video') {
                delete inventoryItem.versions;
            }
            oldInv[inventoryItem.id] = inventoryItem;
        }
        state.acts[action.actId].inventory = oldInv;
    },

    [actions.DELETE_INVENTORY]: (state, action: any) => {
        delete state.acts[action.config.actId].inventory[action.config.inventoryId];
    },

    [actions.CHANGE_INVENTORY_ID]: (state, action: any) => {
        const inventory = { ...state.acts[action.config.actId].inventory };
        delete inventory[action.config.oldInventoryId];
        inventory[action.config.inventoryId] = action.config.inventoryItem;
        state.acts[action.config.actId].inventory = inventory;
    },

    [actions.REORDER_CUTS]: (state, action: any) => {
        const cuts = state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts;
        cuts.sort((a, b) => {
            return a.startFrame - b.startFrame;
        });
        state.acts[action.config.actId].scenes[action.config.sceneId].sceneData.cuts = cuts;
    },

    [actions.DELETE_STORY]: (state, action: any) => {
        return null;
    },

    [actions.FLUSH_STORY]: (state, action: any) => {
        return initialState;
    },

    [actions.ADD_VIEWER]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };
        viewer.activeViewer = action.viewConfig.id;

        // prevent a duplicate tab from being opened
        let exists = false;
        for (const tab of viewer.tabs) {
            if (tab.id === action.viewConfig.id) {
                exists = true;
            }
        }
        if (!exists) {
            const tabs = [...viewer.tabs];
            tabs.push(action.viewConfig);
            viewer.tabs = tabs;
        }

        const currentTabs = viewer.tabs.filter((t) => t);
        const COMPOSITION_TYPES = [ASSET_TYPES.VIDEO_COMPOSITION];

        currentTabs.sort((tab, secondTab) => {
            return COMPOSITION_TYPES.includes(tab.type)
                ? -1
                : COMPOSITION_TYPES.includes(secondTab.type)
                ? 1
                : 0;
        });

        viewer.tabs = currentTabs;

        state.viewer = viewer;
    },

    [actions.REPLACE_VIEWER]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };
        const tabs = [...viewer.tabs];
        viewer.activeViewer = action.viewConfig.id;

        for (let i = 0; i < viewer.tabs.length; i++) {
            const tab = viewer.tabs[i];
            if (tab.type === action.viewConfig.type) {
                tabs[i] = action.viewConfig;
                viewer.tabs = tabs;
                state.viewer = viewer;
                return;
            }
        }

        tabs.push(action.viewConfig);
        viewer.tabs = tabs;
        state.viewer = viewer;
    },

    [actions.SET_ACTIVE]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };
        const tabs = [...viewer.tabs];
        for (const tab of tabs) {
            if (tab.id === action.id) {
                viewer.activeViewer = action.id;
            }
        }
        state.viewer = viewer;
    },

    [actions.CLOSE_VIEWER]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };

        // find the tab to delete in the array
        const idx = getTabIndex(state, action.id);

        // remove it
        if (idx !== null) {
            const tabs = [...viewer.tabs];
            tabs.splice(idx, 1);
            viewer.tabs = tabs;

            state.viewer = viewer;
        }
    },

    [actions.CLOSE_OTHER_VIEWERS]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };

        const idx = getTabIndex(state, action.id);

        if (idx !== null) {
            const tabs = [...viewer.tabs];

            viewer.tabs = [tabs[idx]];

            state.viewer = viewer;
        }
    },

    [actions.CLOSE_RIGHT_VIEWERS]: (state, action: any) => {
        const viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };

        const idx = getTabIndex(state, action.id);

        if (idx !== null) {
            const tabs = viewer.tabs.slice(0, idx + 1);

            viewer.tabs = tabs;

            state.viewer = viewer;
        }
    },

    [assetActions.UPDATE_NAME]: (state, action: any) => {
        let viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };
        viewer = {
            ...viewer,
            tabs: viewer.tabs.map((f: any) => {
                if (f.id === action.id) {
                    return {
                        ...f,
                        label: 'Asset: ' + action.name
                    };
                }
                return f;
            })
        };
        state.viewer = viewer;
    },

    [actions.UPDATE_VIEWER]: (state, action: any) => {
        let viewer = state.viewer ? { ...state.viewer } : { ...NEW_EMPTY_DEFAULT_VIEWER };
        viewer = {
            ...viewer,
            tabs: viewer.tabs.map((f: any) => {
                if (f.id === action.id) {
                    return {
                        ...f,
                        asset: action.asset
                    };
                }
                return f;
            })
        };
        state.viewer = viewer;
    }
});

export default story;
