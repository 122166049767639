import * as React from 'react';
import AssetsTable from './AssetsTable';
import {
    ICON_TRASH,
    ICON_SYNC,
    ICON_LAYER_GROUP,
    ICON_UPLOAD,
    ICON_COPY,
    ICON_UNLOCK,
    ICON_CODE,
    ICON_MAXIMIZE,
    ICON_MINIMIZE,
    ICON_DOWNLOAD
} from '../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logError } from '../../util/notifications';
import { assets as copy, timeline, header } from '../../constants/copy';
import { api } from '../../constants/app';
import { getStoryType, uploadAssetsHandler } from '../../util/story';

import {
    Button,
    ImposiumDropdown,
    Section,
    AssetsUploadMenu,
    deleteAssets,
    resetSelection,
    validateAssetMimetype,
    doAssetTableHydration,
    uploadAssets,
    decoupleAssets,
    CheckboxField,
    deleteAssetsTags,
    downloadAssets
} from '@imposium-hub/components';
import { IStory } from '../../constants/snippets';
import { ASSET_TYPES, DUPLICATABLE_ASSETS, SCENE_TYPES } from '../../constants/story';
import { addViewer, closeViewer } from '../../redux/actions/story';
import {
    createNewHTMLAsset,
    updateEditorConfig,
    uploadHTMLAsset
} from '../../redux/actions/editor';
import { VIEWER_TYPES } from '../../constants/viewer';
import { MAX_FILE_SIZE } from '../../constants/app';
import { faEraser, faTags } from '@fortawesome/free-solid-svg-icons';
import { faGearComplex } from '@fortawesome/pro-regular-svg-icons';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import { openConfirmModal } from '../../util/ui';

interface IAssetsSectionProps {
    story: IStory;
    project: any;
    editor: any;
    assetList: any;
    assetUploads: any;
    asset: { pendingAssets: []; error: any };
    selectedAssets: string[];
    createNewHTMLAsset(): void;
    uploadHTMLAsset(f: any, n: string): void;
    deleteAssets: (apiInstance: any, ids: string[], storyId: string) => any;
    uploadAssets: (apiInstance: any, files: File[]) => any;
    resetSelection: () => any;
    doAssetTableHydration: (apiInstance: any, storyId: string, onErr?: () => any) => any;
    addViewer(c: any): void;
    closeViewer(id): void;
    decoupleAssets: (apiInstance: any, ids: string[], storyId: string) => any;
    deleteAssetsTags: (apiInstance: any, ids: string[], storyId: string) => any;
    updateEditorConfig(i: any): void;
    menuHeight?: number;
}

interface IAssetsSectionState {
    showColumnsFilter: boolean;
    uploadMenuHover: boolean;
    tableHover: boolean;
}

class AssetsSection extends React.PureComponent<IAssetsSectionProps, IAssetsSectionState> {
    private readonly uploadMenuToggleRef: any;

    private readonly assetFilterToggleRef: any;

    private readonly hiddenFileRef: any;

    constructor(p: IAssetsSectionProps) {
        super(p);

        this.state = {
            showColumnsFilter: false,
            uploadMenuHover: false,
            tableHover: false
        };

        this.hiddenFileRef = React.createRef();
        this.uploadMenuToggleRef = React.createRef();
        this.assetFilterToggleRef = React.createRef();
    }

    private doUpload = (e: any): void => {
        const {
            editor: { showUploadsMenu }
        } = this.props;

        const invalidFormat = [];
        const validFiles: any[] = Array.from(e.target.files).filter((f: File) => {
            if (validateAssetMimetype(f)) {
                return validateAssetMimetype(f);
            } else {
                const nameSegments = f.name.split('.');
                const extension = nameSegments[nameSegments.length - 1];
                invalidFormat.push(extension);
            }
        });

        if (invalidFormat.length > 0) {
            const extensions = invalidFormat.join(',');
            const formats = invalidFormat.length === 1 ? 'format' : 'formats';
            logError(
                copy.invalidFileFormat
                    .replace('[extensions]', extensions)
                    .replace('[formats]', formats)
            );
        }

        if (validFiles.some((file) => file.size > MAX_FILE_SIZE)) {
            logError(copy.errorMaxSize);
            e.target.value = null;
            return;
        }

        for (const file of validFiles) {
            if (file.type === 'application/json') {
                const reader = new FileReader();
                reader.onload = (r) => this.props.uploadHTMLAsset(r, file.name);
                reader.readAsText(file);
            }
        }

        const validUploadFiles = validFiles.filter((f) => f.type !== 'application/json');

        if (Array.isArray(validUploadFiles) && validUploadFiles.length > -1) {
            this.showVariablesHandler(true);
            if (showUploadsMenu) {
                this.props.updateEditorConfig({ showUploadsDropdown: true });
            }
            this.props.uploadAssets(api, validUploadFiles);
        }

        this.hiddenFileRef.current.value = '';
    };

    private createNewComposition() {
        this.props.addViewer({
            id: VIEWER_TYPES.NEW_COMPOSITION,
            label: timeline.newComposition,
            type: VIEWER_TYPES.NEW_COMPOSITION
        });
    }

    private doDeleteAssets = (): void => {
        const {
            selectedAssets,
            project: { storyId }
        } = this.props;

        const onYes = () =>
            this.props
                .deleteAssets(api, selectedAssets, storyId)
                .then(() => {
                    for (const id of selectedAssets) {
                        this.props.closeViewer(id);
                    }
                    this.props.resetSelection();
                    this.props.doAssetTableHydration(api, storyId, () =>
                        logError(copy.errorPulling)
                    );
                })
                .catch((e) => {
                    logError(copy.errorDeleting);
                });

        const length: string = selectedAssets.length.toString();

        openConfirmModal({
            onYes: () => onYes(),
            title: header.deleteAssets.replace('[length]', length)
        });
    };

    private doDownloadAssets = (downloadableAssets): void => {
        downloadAssets(api, downloadableAssets).then((url) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.parentNode.removeChild(downloadLink);
        });
    };

    private expandPanelHandler(bool) {
        this.props.updateEditorConfig({
            expandPanel: !bool
        });
    }

    private expandTagsHandler(bool) {
        this.props.updateEditorConfig({
            expandTags: !bool
        });
    }

    private showVariablesHandler(bool) {
        this.props.updateEditorConfig({
            showVariables: !bool
        });
        this.props.doAssetTableHydration(api, this.props.project.storyId);
    }

    private assetColumnsFilterHandler(state) {
        const currState = this.props.editor.assetColumnsFilter;

        const newState = { ...currState, ...state };
        this.props.updateEditorConfig({
            assetColumnsFilter: newState
        });
    }

    private doDecoupleAssets = (decoupleItems: string[]): void => {
        const {
            project: { storyId }
        } = this.props;

        const length: string = decoupleItems.length.toString();

        openConfirmModal({
            onYes: () => this.props.decoupleAssets(api, decoupleItems, storyId),
            title: header.decoupleAssets.replace('[length]', length)
        });
    };

    private deleteAssetsTags = (): void => {
        const {
            selectedAssets,
            project: { storyId }
        } = this.props;

        const onYes = () =>
            this.props.deleteAssetsTags(api, selectedAssets, storyId).catch((e) => {
                logError(copy.errorDeleting);
            });

        const length: string = selectedAssets.length.toString();

        openConfirmModal({
            onYes: () => onYes(),
            title: header.removeTags.replace('[length]', length)
        });
    };

    private duplicateAsset() {
        const { assetList, selectedAssets } = this.props;
        const selectedId = selectedAssets[0];
        const asset = assetList.assets.find((a) => {
            if (a.id === selectedId) {
                return a;
            }
        });

        const newName = asset ? `Copy of ${asset.name}` : null;

        api.duplicateAsset(selectedId, newName)
            .then((res) => {
                this.props.doAssetTableHydration(api, this.props.project.storyId);
            })
            .catch((e) => {
                console.error(e);
            });
    }

    private renderBtnDuplicate() {
        const { selectedAssets, assetList } = this.props;

        // show the duplicate button if there is 1 asset selected, and it's the correct type
        if (selectedAssets.length === 1) {
            const assetId = selectedAssets[0];
            const asset = assetList.assets.find((a) => {
                if (a.id === assetId) {
                    return a;
                }
            });

            if (asset && DUPLICATABLE_ASSETS.indexOf(asset.type) !== -1) {
                return (
                    <Button
                        size='small'
                        style='subtle'
                        key='btn-duplicate-asset'
                        tooltip={copy.tooltipDuplicate}
                        onClick={() => this.duplicateAsset()}>
                        {ICON_COPY}
                    </Button>
                );
            }
        }

        return null;
    }

    public render = (): JSX.Element => {
        const { showColumnsFilter } = this.state;
        const {
            assetList: { loading, assets },
            assetUploads: { uploads },
            asset: { pendingAssets },
            selectedAssets,
            project: { storyId },
            editor: {
                expandPanel,
                expandTags,
                showVariables,
                showUploadsDropdown,
                assetColumnsFilter,
                assetColumnsFilter: {
                    tags,
                    duration,
                    rate,
                    width,
                    height,
                    date_created,
                    last_updated_by,
                    date_updated
                }
            }
        } = this.props;

        const buttons: JSX.Element[] = [];
        const uploadingAssets = [];
        const uploadingErrors = [];

        uploads.map((u) => {
            if (u.error) {
                uploadingErrors.push(true);
            }
            if (u.percent > 0) {
                uploadingAssets.push(true);
            }
        });

        const storyType = getStoryType(this.props.story);
        const btnAddComp =
            storyType === SCENE_TYPES.COMPOSITION ? (
                <Button
                    size='small'
                    color='primary'
                    style='bold'
                    tooltip={copy.newComposition}
                    key='btn-new-composition'
                    onClick={() => this.createNewComposition()}>
                    {header.btnNewComp} {ICON_LAYER_GROUP}
                </Button>
            ) : null;

        const btnAddHTMLTemplate = (
            <Button
                size='small'
                color='primary'
                style='bold'
                tooltip={copy.tooltipNewHTML}
                key='btn-new=html'
                onClick={() => this.props.createNewHTMLAsset()}>
                {header.btnNewHTML} {ICON_CODE}
            </Button>
        );

        const btnUpload = (
            <Button
                key='upload-file'
                onClick={() => this.hiddenFileRef.current.click()}
                style='bold'
                color='primary'
                tooltip={copy.tooltipUpload}
                size='medium'>
                <input
                    multiple
                    className='file-hidden'
                    type='file'
                    ref={this.hiddenFileRef}
                    onChange={(e) => this.doUpload(e)}
                />
                {header.btnUpload} {ICON_UPLOAD}
            </Button>
        );

        const btnDuplicate = this.renderBtnDuplicate();

        const expandTagBtnStyle = {
            color: !expandTags ? 'grey' : 'white'
        };

        const showVariablesBtnStyle = {
            color: !showVariables ? 'grey' : 'white'
        };
        buttons.push(
            <Button
                key='asset-table-expand-panel'
                style='subtle'
                tooltip={copy.expandPanel}
                onClick={() => this.expandPanelHandler(expandPanel)}>
                {expandPanel ? ICON_MINIMIZE : ICON_MAXIMIZE}
            </Button>
        );

        buttons.push(
            <Button
                key='asset-table-variables'
                style='subtle'
                tooltip={copy.showVariables}
                onClick={() => this.showVariablesHandler(showVariables)}>
                <FontAwesomeIcon
                    style={showVariablesBtnStyle}
                    icon={faBracketsCurly}
                />
            </Button>
        );
        buttons.push(
            <Button
                key='asset-table-expand-tags'
                style='subtle'
                tooltip={copy.showTags}
                onClick={() => this.expandTagsHandler(expandTags)}>
                <FontAwesomeIcon
                    style={expandTagBtnStyle}
                    icon={faTags}
                />
            </Button>
        );

        buttons.push(
            <Button
                key='asset-table-filter'
                style='subtle'
                buttonRef={this.assetFilterToggleRef}
                tooltip='Show/Hide Columns'
                onClick={() => this.setState({ showColumnsFilter: !showColumnsFilter })}>
                <FontAwesomeIcon icon={faGearComplex} />
            </Button>
        );

        if (pendingAssets.length !== 0) {
            buttons.push(
                <Button
                    key='asset-loading-btn'
                    style='subtle'>
                    <FontAwesomeIcon
                        className={pendingAssets ? 'icon-spin-infinite' : undefined}
                        icon={faSpinner}
                    />
                    <sup className='menu-count-superscript'>({pendingAssets.length})</sup>
                </Button>
            );
        }

        const downloadableAssets = selectedAssets
            .map(
                (id) =>
                    assets.find(
                        (asset) =>
                            asset.id === id &&
                            asset.type !== ASSET_TYPES.VIDEO_COMPOSITION &&
                            asset.type !== ASSET_TYPES.HTML
                    )?.id
            )
            .filter((e) => {
                return e !== undefined;
            });

        if (downloadableAssets.length > 0) {
            buttons.push(
                <Button
                    key='download-assets-btn'
                    tooltip={copy.tooltipDownload}
                    onClick={() => this.doDownloadAssets(downloadableAssets)}
                    style='subtle'
                    size='medium'>
                    {ICON_DOWNLOAD}
                    <sup className='menu-count-superscript'>({downloadableAssets.length})</sup>
                </Button>
            );

            buttons.push(
                <Button
                    key='delete-assets-btn'
                    tooltip={copy.tooltipDelete}
                    onClick={() => this.doDeleteAssets()}
                    style='subtle'
                    size='medium'>
                    {ICON_TRASH}
                    <sup className='menu-count-superscript'>({selectedAssets.length})</sup>
                </Button>
            );

            buttons.push(
                <Button
                    key='delete-assets-tag-btn'
                    tooltip={copy.tooltipRemoveTags}
                    onClick={() => this.deleteAssetsTags()}
                    style='subtle'
                    size='medium'>
                    <FontAwesomeIcon icon={faEraser} />
                    <sup className='menu-count-superscript'>({selectedAssets.length})</sup>
                </Button>
            );

            let decoupleItems = selectedAssets;

            for (const item of decoupleItems) {
                const decouplable = assets.find((asset) => {
                    return asset.id === item;
                });

                if (
                    decouplable.story_id === null ||
                    decouplable.type === ASSET_TYPES.VIDEO_COMPOSITION
                ) {
                    decoupleItems = decoupleItems.filter((i) => i !== item);
                }
            }

            if (decoupleItems.length > 0 && !showVariables) {
                buttons.push(
                    <Button
                        key='decouple-assets-btn'
                        tooltip={copy.tooltipDecouple}
                        onClick={() => this.doDecoupleAssets(decoupleItems)}
                        style='subtle'
                        size='medium'>
                        {ICON_UNLOCK}
                        <sup className='menu-count-superscript'>({decoupleItems.length})</sup>
                    </Button>
                );
            }
        }

        if (!showVariables) {
            buttons.push(btnDuplicate);
        }

        const spinInfinite =
            uploadingAssets.length !== uploadingErrors.length && uploadingAssets.length > 0
                ? 'icon-spin-infinite'
                : undefined;

        buttons.push(
            <Button
                key='upload-menu-toggle'
                buttonRef={this.uploadMenuToggleRef}
                onClick={() =>
                    this.props.updateEditorConfig({ showUploadsDropdown: !showUploadsDropdown })
                }
                style='subtle'
                tooltip={copy.tooltipViewUploads}
                size='medium'>
                <FontAwesomeIcon
                    className={spinInfinite}
                    icon={faSpinner}
                />
                <sup className='menu-count-superscript'>({uploads.length})</sup>
            </Button>,
            <Button
                key='refresh-list-btn'
                onClick={() => this.props.doAssetTableHydration(api, storyId)}
                style='subtle'
                tooltip={copy.tooltipRefresh}
                size='medium'>
                {ICON_SYNC}
            </Button>
        );

        return (
            <div className='assets'>
                <Section
                    className='assetUploads'
                    isLoading={uploadingAssets.length > 0}
                    buttons={buttons}
                    title={
                        <>
                            {btnUpload}&nbsp;{btnAddHTMLTemplate}&nbsp;{btnAddComp}
                        </>
                    }>
                    <AssetsTable
                        loading={loading}
                        doHydrate={() =>
                            this.props.doAssetTableHydration(api, storyId, () =>
                                logError(copy.errorPulling)
                            )
                        }
                        columnsFilter={assetColumnsFilter}
                        uploadMenuHover={this.state.uploadMenuHover}
                        onTableHover={(o) => this.setState({ tableHover: o })}
                    />
                    <AssetsUploadMenu
                        api={api}
                        updateEditorConfig={(i) => this.props.updateEditorConfig(i)}
                        onDrop={(i, m) => uploadAssetsHandler(i, m)}
                        show={showUploadsDropdown}
                        toggleRef={this.uploadMenuToggleRef}
                        onOutsideClick={() =>
                            this.props.updateEditorConfig({ showUploadsDropdown: false })
                        }
                        onMenuHover={(o) => this.setState({ uploadMenuHover: o })}
                        tableHover={this.state.tableHover}
                    />
                    <ImposiumDropdown
                        position='bottomright'
                        show={showColumnsFilter}
                        toggleRef={this.assetFilterToggleRef}
                        onOutsideClick={() => this.setState({ showColumnsFilter: false })}>
                        <div className='column-filter-menu'>
                            <div className='column-filter-title'>Show/Hide Columns</div>
                            <div
                                className='column-filter-list'
                                onClick={() => this.assetColumnsFilterHandler({ tags: !tags })}>
                                <CheckboxField
                                    label='Tags'
                                    value={tags}
                                    onChange={(i) => this.assetColumnsFilterHandler({ tags: i })}
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() =>
                                    this.assetColumnsFilterHandler({ duration: !duration })
                                }>
                                <CheckboxField
                                    label='Duration'
                                    value={duration}
                                    onChange={(i) =>
                                        this.assetColumnsFilterHandler({ duration: i })
                                    }
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() => this.assetColumnsFilterHandler({ rate: !rate })}>
                                <CheckboxField
                                    label='Frame Rate'
                                    value={rate}
                                    onChange={(i) => this.assetColumnsFilterHandler({ rate: i })}
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() => this.assetColumnsFilterHandler({ width: !width })}>
                                <CheckboxField
                                    label='Width'
                                    value={width}
                                    onChange={(i) => this.assetColumnsFilterHandler({ width: i })}
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() => this.assetColumnsFilterHandler({ height: !height })}>
                                <CheckboxField
                                    label='Height'
                                    value={height}
                                    onChange={(i) => this.assetColumnsFilterHandler({ height: i })}
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() =>
                                    this.assetColumnsFilterHandler({
                                        last_updated_by: !last_updated_by
                                    })
                                }>
                                <CheckboxField
                                    label='Last Modified By'
                                    value={last_updated_by}
                                    onChange={(i) =>
                                        this.assetColumnsFilterHandler({ last_updated_by: i })
                                    }
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() =>
                                    this.assetColumnsFilterHandler({ date_updated: !date_updated })
                                }>
                                <CheckboxField
                                    label='Last Modified'
                                    value={date_updated}
                                    onChange={(i) =>
                                        this.assetColumnsFilterHandler({ date_updated: i })
                                    }
                                />
                            </div>
                            <div
                                className='column-filter-list'
                                onClick={() =>
                                    this.assetColumnsFilterHandler({ date_created: !date_created })
                                }>
                                <CheckboxField
                                    label='Date Created'
                                    value={date_created}
                                    onChange={(i) =>
                                        this.assetColumnsFilterHandler({ date_created: i })
                                    }
                                />
                            </div>
                        </div>
                    </ImposiumDropdown>
                </Section>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            resetSelection,
            deleteAssets,
            addViewer,
            closeViewer,
            doAssetTableHydration,
            uploadAssets,
            decoupleAssets,
            createNewHTMLAsset,
            updateEditorConfig,
            deleteAssetsTags,
            uploadHTMLAsset
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        project: state.project,
        story: state.story,
        editor: state.editor,
        asset: state.asset,
        assetList: state.assetList,
        assetUploads: state.assetUploads,
        selectedAssets: state.selectedAssets
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsSection);
