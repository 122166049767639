import {
    AppWrapper,
    AuthService,
    checkStoryId,
    clearStoryPublishStatus,
    doAssetTableHydration,
    getLastModifiedStoryInOrg,
    getStoryPublishStatus,
    ImposiumHeader,
    Modal,
    resetFilters,
    scrapeEmail,
    SessionService,
    validateAccessLevel
} from '@imposium-hub/components';

import PublishWizard from '@imposium-hub/components/dist/esm/components/publish-wizard/PublishWizard';
import hotkeys from 'hotkeys-js';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { api, initApi, SERVICE_ID } from '../constants/app';
import { assets as copy, header, settings as settingsCopy } from '../constants/copy';
import { HEADER_HEIGHT, PROJECT_SETTINGS_TABS } from '../constants/editor';
import { IStory } from '../constants/snippets';
import { SCENE_TYPES } from '../constants/story';
import {
    getBatchExport,
    importBatchFromCsv,
    importBatchFromDataset,
    renderBatch,
    resetBatchFailedStatus
} from '../redux/actions/batch-jobs';
import { createFreshBatch, getBatches } from '../redux/actions/batches';
import { updateChanges } from '../redux/actions/changes';
import { resetEditorConfig, updateEditorConfig } from '../redux/actions/editor';
// import { setOrg } from '../redux/actions/org';
import { getQueues } from '../redux/actions/queues';
import { flushStoryState, getStory, saveStory } from '../redux/actions/story';
import { getDatasets, clearActiveDataset } from '../redux/actions/datasets';
import type { IChanges } from '../redux/reducers/changes';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import { clearNotifications, logError, logNotification } from '../util/notifications';
import { replaceRoute, pushRoute } from '../util/routing';
import { getStoryType } from '../util/story';
import BlockingJobOverlay from './BlockingJobOverlay';
import CompositionStoryInterface from './CompositionStoryInterface';
import CreateStoryPrompt from './CreateStoryPrompt';
import GlobalLoading from './GlobalLoading';
import ImageStoryInterface from './ImageStoryInterface';
import NewProjectSettings from './NewProjectSettings';
import ProjectSettings from './ProjectSettings';
import VideoStoryInterface from './VideoStoryInterface';
import { initPendo } from '../util/pendo';
import { FilteredListModal } from './filterListTable';
import ErrorStoryPrompt from './ErrorStoryPrompt';
import { openConfirmModal } from '../util/ui';

interface IAppProps {
    access: any;
    auth: any;
    location: any;
    params: any;
    story: IStory;
    editor: IEditor;
    project: IProject;
    changes: IChanges;
    undo: () => void;
    redo: () => void;
    flushStoryState: () => void;
    doAssetTableHydration: (apiInstance: any, storyId: string, onErr?: () => any) => any;
    getStory(id: string): any;
    getDatasets(id: string, activeDatasetId?: string): any;
    clearActiveDataset(): any;
    saveStory(): any;
    // setOrg(orgId: string): void;
    updateEditorConfig(c): void;
    updateChanges(c): void;
    resetEditorConfig(): void;
    clearStoryPublishStatus(apiInstance: any): void;
    getStoryPublishStatus(apiInstance: any, sId: string): any;
    createFreshBatch: (e: string) => any;
    getBatches: () => any;
    getBatchExport: (batchId: string) => any;
    importBatchFromCsv: (
        storyId: string,
        batchId: string,
        batchName: string,
        csvFile: File,
        accessKey: string,
        compId: string,
        images: File,
        video: File,
        expID: string,
        addEmbed?: boolean,
        addMedia?: boolean
    ) => any;
    importBatchFromDataset: (
        batchId: string,
        datasetId: string,
        accessKey: string,
        compId: string,
        addEmbed?: boolean,
        addMedia?: boolean
    ) => any;
    batchJobs: any;
    renderBatch: (batchId: string, postRenderActions?: any) => any;
    getQueues: () => any;
    resetFilters(): any;
    resetBatchFailedStatus(): void;
}

class App extends React.PureComponent<IAppProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            logout: () => this.doLogout(true),
            closeModal: () => this.closeSettingsModal(),
            closePublishModal: () => this.closePublishModal(),
            closeNewStoryModal: () => this.closeNewStoryModal(),
            closeFilterModal: () => this.closeFilterModal()
        };
    }

    public componentDidMount() {
        this.bindHotkeys();

        window.onbeforeunload = () => {
            if (window.location.hostname.indexOf('localhost') === -1) {
                const {
                    changes: { unsaved }
                } = this.props;
                if (unsaved) {
                    return settingsCopy.confirmLeave;
                }
            }
        };
    }

    public componentDidUpdate(prevProps): void {
        const {
            story,
            params: { story_id: storyIdParam },
            location: {
                query: { organization_id: orgIdParam }
            }
        } = this.props;

        const {
            location: {
                query: { organization_id: prevOrgIdParam }
            }
        } = prevProps;

        if (storyIdParam === undefined && story?.id) {
            replaceRoute(`/${story.id}?organization_id=${orgIdParam}`, true);
        } else if (story && prevOrgIdParam !== orgIdParam && story?.id !== storyIdParam) {
            this.changeOrg(orgIdParam, storyIdParam);
        } else if (story?.id !== storyIdParam && orgIdParam) {
            this.onStoryIdChange(storyIdParam);
        }
    }

    private onStoryIdChange(storyId) {
        const {
            editor: { activeDatasetId }
        } = this.props;
        this.props.clearStoryPublishStatus(api);
        this.props.getStory(storyId).then(() => {
            this.props.updateEditorConfig({ loading: false, showVariables: false });
            this.props.getDatasets(storyId, activeDatasetId);
            this.props.getStoryPublishStatus(api, storyId).catch((e) => {
                logError(e);
            });
            this.props.resetFilters();
        });
    }

    public onAuthenticated = (activeOrgId: string, activeStoryId: string): void => {
        const {
            location: {
                query: { organization_id: orgIdParam }
            },
            params: { story_id: storyIdParam },
            auth: { idTokenPayload }
        } = this.props;

        initPendo(idTokenPayload);
        if (
            !orgIdParam ||
            !storyIdParam ||
            activeOrgId !== orgIdParam ||
            activeStoryId !== storyIdParam
        ) {
            if (activeStoryId) {
                replaceRoute(`/${activeStoryId}?organization_id=${activeOrgId}`, true);
            } else {
                replaceRoute(`?organization_id=${activeOrgId}`, true);
            }
        }

        if (activeOrgId) {
            initApi(activeOrgId, this.props.auth.accessToken);
            this.props.getQueues();
            // this.props.setOrg(activeOrgId);
        }

        if (activeStoryId) {
            this.onStoryIdChange(activeStoryId);
        } else {
            this.props.updateEditorConfig({ loading: false });
        }
    };

    private onAuthenticationFailure = (): void => {
        this.doLogout(false);
    };

    private doLogout = (doFederatedLogout: boolean): void => {
        if (doFederatedLogout && SessionService.getSession()) {
            SessionService.removeSession();
            AuthService.logout();
        } else {
            pushRoute('/auth');
        }
    };

    private changeStory = (s: any): void => {
        const {
            auth: {
                idTokenPayload: { exp }
            },
            editor: { activeDatasetId },
            changes: { unsaved }
        } = this.props;

        const changingStory = () => {
            this.props.resetEditorConfig();
            this.props.clearStoryPublishStatus(api);
            this.props.clearActiveDataset();
            this.props.updateEditorConfig({ loading: true, activeDatasetId: null });
            this.props.getStory(s.id).then(() => {
                SessionService.updateSession({ story_id: s.id }, exp);
                pushRoute(`/${s.id}`);
                this.props.updateEditorConfig({ loading: false });
                this.props.getDatasets(s.id, activeDatasetId);
                this.props.getStoryPublishStatus(api, s.id).catch((e) => {
                    logError(e);
                });
            });
        };

        if (unsaved) {
            const onYes = () => {
                this.props.saveStory();
                changingStory();
            };

            openConfirmModal({
                showCancel: true,
                noLabel: header.noSave,
                onNo: () => changingStory(),
                yesLabel: header.onSave,
                onYes: () => onYes(),
                title: header.checkUnsaved
            });
        } else {
            changingStory();
        }
    };

    private changeOrg = (newOrgId: string, storyId?: string): void => {
        const {
            auth: {
                idToken,
                idTokenPayload: { exp }
            },
            editor: { activeDatasetId },
            changes: { unsaved }
        } = this.props;

        const changingOrg = () => {
            this.props.resetFilters();
            clearNotifications();

            SessionService.getAccessData(idToken, import.meta.env.VITE_IMPOSIUM_BASE)
                .then((res) => {
                    const activeStoryId: string = storyId
                        ? storyId
                        : getLastModifiedStoryInOrg(newOrgId, res);
                    this.props.updateEditorConfig({ loading: true, activeDatasetId: null });
                    this.props.clearActiveDataset();
                    const onValidChange = (): void => {
                        SessionService.updateSession(
                            { organization_id: newOrgId, story_id: activeStoryId },
                            exp
                        );
                        // If there is a story ID, go to the story, if not, go to the root with the org ID
                        if (activeStoryId) {
                            pushRoute(`/${activeStoryId}?organization_id=${newOrgId}`, true);
                        } else {
                            pushRoute(`?organization_id=${newOrgId}`, true);
                        }
                        this.props.updateEditorConfig({ loading: false });
                        if (activeStoryId) {
                            this.props.doAssetTableHydration(api, activeStoryId);
                            this.props.getDatasets(activeStoryId, activeDatasetId);
                            this.props.getStoryPublishStatus(api, activeStoryId).catch((e) => {
                                logError(e);
                            });
                        }
                    };

                    if (validateAccessLevel(newOrgId, SERVICE_ID, res)) {
                        initApi(newOrgId, idToken);
                        this.props.getQueues();
                        // this.props.setOrg(newOrgId);
                        this.props.resetEditorConfig();
                        this.props.clearStoryPublishStatus(api);
                        this.props.updateEditorConfig({ loading: true });
                        this.props.getDatasets(activeStoryId, activeDatasetId);

                        if (activeStoryId) {
                            this.props.getStory(activeStoryId).then(() => onValidChange());
                        } else {
                            onValidChange();
                            this.props.flushStoryState();
                        }
                    } else {
                        SessionService.updateSession(
                            { organization_id: newOrgId, story_id: activeStoryId },
                            exp
                        );
                        pushRoute(`/${activeStoryId}?organization_id=${newOrgId}`, true);
                    }
                })
                .catch((e: Error) => {
                    console.error(e);
                });
        };

        if (unsaved) {
            const onYes = () => {
                this.props.saveStory();
                changingOrg();
            };

            openConfirmModal({
                showCancel: true,
                noLabel: header.noSave,
                onNo: () => changingOrg(),
                yesLabel: header.onSave,
                onYes: () => onYes(),
                title: header.checkUnsaved
            });
        } else {
            changingOrg();
        }
    };

    private closeSettingsModal() {
        const {
            editor: { settingsModalOpen, activeSettingsTab }
        } = this.props;

        this.props.updateEditorConfig({
            postSettingsAction: null,
            activeSettingsTab:
                activeSettingsTab === PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS
                    ? PROJECT_SETTINGS_TABS.PROJECT
                    : activeSettingsTab,
            settingsModalOpen: !settingsModalOpen
        });
    }

    private closeFilterModal() {
        const {
            editor: { openFilteredList }
        } = this.props;

        this.props.updateEditorConfig({
            openFilteredList: !openFilteredList
        });
    }

    public closeNewStoryModal() {
        const {
            editor: { newStoryModalOpen }
        } = this.props;

        this.props.updateEditorConfig({
            newStoryModalOpen: !newStoryModalOpen
        });
    }

    public closePublishModal() {
        const {
            editor: { publishWizardOpen }
        } = this.props;

        this.props.updateEditorConfig({
            publishWizardOpen: !publishWizardOpen,
            publishDataset: false
        });
    }

    public bindHotkeys() {
        hotkeys('command+z, control+z', (e) => this.undo(e));
        hotkeys('p', { keyup: true }, (e) => this.togglePropIds(e));
        hotkeys('command+shift+z, control+y', (e) => this.redo(e));
        hotkeys('command+s, control+s', (e) => this.save(e));
    }

    private togglePropIds(e) {
        const showCopyPropIds = e.type === 'keyup' ? false : true;
        if (showCopyPropIds !== this.props.editor.showCopyPropIds) {
            this.props.updateEditorConfig({ showCopyPropIds });
        }
    }

    private save(e) {
        e.preventDefault();
        e.stopPropagation();

        const {
            editor: { reHydrateAssetTable },
            story: { id }
        } = this.props;

        this.props.saveStory().then(() => {
            this.props.getStoryPublishStatus(api, id);
            if (reHydrateAssetTable) {
                setTimeout(() => {
                    this.props.doAssetTableHydration(api, id);
                    this.props.updateEditorConfig({ reHydrateAssetTable: false });
                }, 2000);
            }
        });
    }

    private redo(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.redo();
        this.props.updateChanges({
            unsaved: true
        });
    }

    private undo(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.undo();
        this.props.updateChanges({
            unsaved: true
        });
    }

    public renderStoryInterface() {
        const {
            story,
            location: {
                query: { organization_id }
            }
        } = this.props;

        if (story) {
            const type = getStoryType(story);

            switch (type) {
                case SCENE_TYPES.COMPOSITION:
                    return (
                        <CompositionStoryInterface
                            story={story}
                            organizationId={organization_id}
                            onStoryChange={this.changeStory}
                        />
                    );
                case SCENE_TYPES.VIDEO:
                    return (
                        <VideoStoryInterface
                            story={story}
                            onStoryChange={this.changeStory}
                        />
                    );
                case SCENE_TYPES.IMAGE:
                    return (
                        <ImageStoryInterface
                            story={story}
                            onStoryChange={this.changeStory}
                        />
                    );
            }
        } else {
            return <CreateStoryPrompt />;
        }
    }

    private renderBlockingJob() {
        const {
            editor: { blockingJobRunning }
        } = this.props;

        if (blockingJobRunning) {
            return <BlockingJobOverlay />;
        }
    }

    public render() {
        const {
            story,
            auth,
            project: { compositionId },
            project,
            editor: {
                settingsModalOpen,
                newStoryModalOpen,
                publishWizardOpen,
                loading,
                publishDataset,
                activeDatasetId,
                fromCrM
            },
            location: {
                query: { organization_id: orgIdParam }
            },
            params: { story_id: storyIdParam },
            batchJobs,
            access
        } = this.props;

        const activeEmail: string = scrapeEmail(auth);
        const checkStory = checkStoryId(storyIdParam, orgIdParam, access);
        const CrMLink =
            fromCrM && story
                ? `${import.meta.env.VITE_CRM_BASE}/library/${story.creativeLibraryId}/creative/${
                      story.creativeId
                  }/versions/`
                : null;
        return (
            <AppWrapper
                baseUrl={import.meta.env.VITE_IMPOSIUM_BASE}
                serviceId={SERVICE_ID}
                auth0ClientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                auth0Domain={import.meta.env.VITE_AUTH0_DOMAIN}
                organizationId={orgIdParam}
                storyId={storyIdParam}
                onAuthenticated={this.onAuthenticated}
                onAuthenticationFailure={this.onAuthenticationFailure}>
                <ImposiumHeader
                    email={activeEmail}
                    baseUrl={import.meta.env.VITE_IMPOSIUM_BASE}
                    activeOrganization={orgIdParam}
                    activeStory={storyIdParam}
                    hideStoryPicker={fromCrM}
                    hideDocs={fromCrM}
                    showFTLogo={false}
                    hideOrgPicker={fromCrM}
                    CrMLink={CrMLink}
                    onStoryChange={this.changeStory}
                    onOrganizationChange={this.changeOrg}
                    logout={this.evtHandlers.logout}
                />

                <DndProvider backend={HTML5Backend}>
                    {loading ? (
                        !checkStory ? (
                            <ErrorStoryPrompt />
                        ) : (
                            <GlobalLoading />
                        )
                    ) : (
                        this.renderStoryInterface()
                    )}
                    <FilteredListModal />
                    <Modal
                        onRequestClose={this.evtHandlers.closeModal}
                        wrapperStyle={{
                            top: HEADER_HEIGHT,
                            left: '0px',
                            width: '100%',
                            height: `calc(100% - ${HEADER_HEIGHT}px)`
                        }}
                        style={{
                            width: '675px',
                            height: '70%',
                            top: '10%',
                            left: 'calc((100% - 675px) / 2)'
                        }}
                        isOpen={settingsModalOpen}>
                        <ProjectSettings />
                    </Modal>
                    <Modal
                        onRequestClose={this.evtHandlers.closeNewStoryModal}
                        wrapperStyle={{
                            top: HEADER_HEIGHT,
                            left: '0px',
                            width: '100%',
                            height: `calc(100% - ${HEADER_HEIGHT}px)`
                        }}
                        style={{
                            width: '600px',
                            height: '50%',
                            top: '10%',
                            left: 'calc((100% - 600px) / 2)'
                        }}
                        isOpen={newStoryModalOpen}>
                        <NewProjectSettings />
                    </Modal>
                    <Modal
                        onRequestClose={this.evtHandlers.closePublishModal}
                        wrapperStyle={{
                            top: HEADER_HEIGHT,
                            left: '0px',
                            width: '100%',
                            height: `calc(100% - ${HEADER_HEIGHT}px)`
                        }}
                        style={{
                            width: '600px',
                            height: '70%',
                            top: '10%',
                            left: 'calc((100% - 600px) / 2)'
                        }}
                        isOpen={publishWizardOpen}>
                        {story && (
                            <PublishWizard
                                api={api}
                                fromCrM={fromCrM}
                                creativeManagerBaseUrl={import.meta.env.VITE_CRM_BASE}
                                onClose={this.evtHandlers.closePublishModal}
                                story={story}
                                project={project}
                                handleError={(e) => logError(e)}
                                createFreshBatch={this.props.createFreshBatch}
                                getBatches={this.props.getBatches}
                                getBatchExport={this.props.getBatchExport}
                                importBatchFromCsv={this.props.importBatchFromCsv}
                                publishDataset={publishDataset}
                                activeDatasetId={activeDatasetId}
                                importBatchFromDataset={this.props.importBatchFromDataset}
                                handleNotification={(e) => logNotification(e)}
                                batchJobs={batchJobs}
                                renderBatch={this.props.renderBatch}
                                activeComposition={compositionId}
                                onBackBtn={this.props.resetBatchFailedStatus}
                            />
                        )}
                    </Modal>
                    {this.renderBlockingJob()}
                </DndProvider>
            </AppWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        undo: () => dispatch(UndoActionCreators.undo()),
        redo: () => dispatch(UndoActionCreators.redo()),
        updateEditorConfig: (c) => dispatch(updateEditorConfig(c)),
        updateChanges: (c) => dispatch(updateChanges(c)),
        resetEditorConfig: () => dispatch(resetEditorConfig()),
        getStoryPublishStatus: (a, s) => dispatch(getStoryPublishStatus(a, s)),
        clearStoryPublishStatus: (a) => dispatch(clearStoryPublishStatus(a)),
        getStory: (i) => dispatch(getStory(i)),
        getDatasets: (i, a) => dispatch(getDatasets(i, a)),
        clearActiveDataset: () => dispatch(clearActiveDataset()),
        // setOrg: (i) => dispatch(setOrg(i)),
        saveStory: () => dispatch(saveStory()),
        flushStoryState: () => dispatch(flushStoryState()),
        doAssetTableHydration: (a, s) =>
            dispatch(doAssetTableHydration(a, s, () => logError(copy.errorPulling))),
        createFreshBatch: (i) => dispatch(createFreshBatch(i)),
        getBatches: () => dispatch(getBatches()),
        getBatchExport: (i) => dispatch(getBatchExport(i)),
        importBatchFromCsv: (storyId, batchId, csvFile, accessKey, compId, addEmbed, addMedia) =>
            dispatch(
                importBatchFromCsv(storyId, batchId, csvFile, accessKey, compId, addEmbed, addMedia)
            ),
        importBatchFromDataset: (batchId, datasetId, accessKey, compId, addEmbed, addMedia) =>
            dispatch(
                importBatchFromDataset(batchId, datasetId, accessKey, compId, addEmbed, addMedia)
            ),
        renderBatch: (i, a) => dispatch(renderBatch(i, a)),
        getQueues: () => dispatch(getQueues()),
        resetFilters: () => dispatch(resetFilters()),
        resetBatchFailedStatus: () => dispatch(resetBatchFailedStatus())
    };
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        project: state.project,
        changes: state.changes,
        editor: state.editor,
        auth: state.auth,
        access: state.access,
        batchJobs: state.batchJobs
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
