import * as React from 'react';
import {
    VARIABLE_TYPE_OPTIONS,
    BOOLEAN_DEFAULT_OPTIONS,
    VARIABLE_TYPES,
    RESERVED_VARIABLE_IDS
} from '../constants/story';
import {
    colorPresets,
    DEFAULT_COLOR,
    NEW_COLOR_VARIABLE_DEFAULT,
    NEW_VARIABLE_DEFAULT
} from '../constants/snippets';
import {
    ListField,
    ButtonGroupField,
    NumberField,
    SelectField,
    CheckboxField,
    TextField,
    Card,
    Button,
    HRule,
    ColorField
} from '@imposium-hub/components';
import { logError } from '../util/notifications';
import hotkeys from 'hotkeys-js';
import { fields as copy } from '../constants/copy';
import { IVariable } from '../constants/snippets';
import MediaVariableDefault from './MediaVariableDefault';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { IProject } from '../redux/reducers/project';
import {
    ICON_COPY,
    ICON_TRASH,
    ICON_TIMES,
    ICON_CHECK,
    getVariableIcon,
    ICON_LOCK,
    ICON_EDIT
} from '../constants/icons';
import VideoVariableVersions from './VideoVariableVersions';
import { IEditor } from '../redux/reducers/editor';
import { formatColor, openConfirmModal } from '../util/ui';

interface IVariableProps {
    config: IVariable;
    project: IProject;
    editor: IEditor;
    variables: IVariable[];
    onUpdate(i, c, o?): void;
    onDuplicate(i): void;
    onDelete(i): void;
    addingNew?: boolean;
    onSaveNew?(): void;
    onCancelNew?(): void;
}

interface IVariableState {
    unlocked: boolean;
    name: string;
}

class Variable extends React.PureComponent<IVariableProps, IVariableState> {
    private idFieldRef: any;

    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.idFieldRef = React.createRef();

        this.evtHandlers = {
            name: (v) => this.inputChanged('name', v),
            id: (v) => this.idChanged(v),
            enabled: (v) => this.inputChanged('enabled', v),
            optional: (v) => this.inputChanged('optional', v),
            maxDuration: (v) => this.inputChanged('maxDuration', v),
            autoModerate: (v) => this.inputChanged('autoModerate', v),
            versions: (v) => this.inputChanged('versions', v),
            confirmDelete: () => this.confirmDelete(),
            duplicate: () => this.props.onDuplicate(this.props.config.id),
            includeInExp: (v) => this.inputChanged('includeInExperienceData', v),
            typeChanged: (v) => this.typeChanged(v),
            logError: (e) => logError(e),
            defaultOptions: (v) => this.presetInputChanged('defaultItem', 'options', v),
            defaultSrc: (v) => this.presetInputChanged('defaultItem', 'src', v),
            clearMediaDefault: (e) => this.clearMediaPreset(e, 'defaultItem'),
            mediaDefaultChanged: (e) => this.mediaPresetChanged(e, 'defaultItem'),
            previewSrc: (v) => this.presetInputChanged('previewItem', 'src', v),
            clearMediaPreview: (e) => this.clearMediaPreset(e, 'previewItem'),
            mediaPreviewChanged: (e) => this.mediaPresetChanged(e, 'previewItem'),
            save: () => this.props.onSaveNew(),
            cancel: () => this.props.onCancelNew()
        };

        this.state = {
            unlocked: false,
            name: props.config.name
        };
    }

    public componentDidMount() {
        if (this.props.addingNew) {
            this.bindHotkeys();
            const idField = this.idFieldRef.current;
            if (idField) {
                idField.setEditing(true);
            }
        }
    }

    public componentDidUpdate(prevProps: IVariableProps) {
        if (!this.props.addingNew && prevProps.addingNew) {
            this.unBindHotkeys();
        }
    }

    public componentWillUnmount() {
        this.unBindHotkeys();
    }

    private bindHotkeys() {
        hotkeys('enter', this.evtHandlers.save);
    }

    private unBindHotkeys() {
        hotkeys.unbind('enter', this.evtHandlers.save);
    }

    public presetInputChanged(key, field, input) {
        const value = input.rgb ? formatColor(input, true) : input;
        const newConfig = { ...this.props.config };
        const newPreset = newConfig[key] ? { ...newConfig[key] } : this.newPreset();
        newPreset[field] = value;
        newConfig[key] = newPreset;
        this.props.onUpdate(newConfig.id, newConfig);
    }

    private newPreset() {
        const preset = { ...NEW_VARIABLE_DEFAULT };
        preset.type = this.props.config.type;
        return preset;
    }

    private mediaPresetChanged(data, key) {
        const newConfig = { ...this.props.config };
        newConfig[key] = data;
        this.props.onUpdate(newConfig.id, newConfig);
    }

    private clearMediaPreset(e, key) {
        e.preventDefault();
        e.stopPropagation();

        const newConfig = { ...this.props.config };
        const newPreset = { ...NEW_VARIABLE_DEFAULT };
        newPreset.type = this.props.config.type;
        newConfig[key] = newPreset;

        this.props.onUpdate(newConfig.id, newConfig);
    }

    public getPresetField(
        item,
        label,
        tooltip,
        srcHandler,
        mediaHandler,
        clearMediaHandler,
        disableClear = false
    ) {
        const { config } = this.props;
        let presetItem = item;
        const defaultItem = config.defaultItem;

        if (!presetItem) {
            presetItem = { ...NEW_VARIABLE_DEFAULT };
            presetItem.type = config.type;
        }

        if (config.type === VARIABLE_TYPES.COLOR && presetItem.src === null) {
            presetItem = { ...presetItem, src: DEFAULT_COLOR };
        }

        switch (config.type.toLowerCase()) {
            case VARIABLE_TYPES.TEXT:
                return (
                    <TextField
                        label={label}
                        tooltip={tooltip}
                        value={presetItem.src}
                        onChange={srcHandler}
                    />
                );
            case VARIABLE_TYPES.NUMBER:
                return (
                    <NumberField
                        label={label}
                        tooltip={tooltip}
                        value={presetItem.src}
                        onChange={srcHandler}
                    />
                );
            case VARIABLE_TYPES.ENUM:
                const defaultOptions = defaultItem.options ? [...defaultItem.options] : [];
                const dropdownOptions = [...[''], ...defaultOptions];
                return (
                    <SelectField
                        label={label}
                        tooltip={tooltip}
                        value={presetItem.src || ''}
                        options={dropdownOptions}
                        onChange={srcHandler}
                    />
                );
            case VARIABLE_TYPES.BOOLEAN:
                return (
                    <ButtonGroupField
                        label={label}
                        tooltip={tooltip}
                        value={presetItem.src}
                        options={BOOLEAN_DEFAULT_OPTIONS}
                        onChange={srcHandler}
                    />
                );
                break;
            case VARIABLE_TYPES.IMAGE:
                return (
                    <MediaVariableDefault
                        config={presetItem}
                        label={label}
                        tooltip={tooltip}
                        disableClear={disableClear}
                        onClear={clearMediaHandler}
                        onChange={mediaHandler}
                    />
                );
                break;
            case VARIABLE_TYPES.VIDEO:
                return (
                    <MediaVariableDefault
                        config={presetItem}
                        label={label}
                        tooltip={tooltip}
                        disableClear={disableClear}
                        onClear={clearMediaHandler}
                        onChange={mediaHandler}
                    />
                );
                break;
            case VARIABLE_TYPES.AUDIO:
                return (
                    <MediaVariableDefault
                        config={presetItem}
                        label={label}
                        tooltip={tooltip}
                        onClear={clearMediaHandler}
                        onChange={mediaHandler}
                    />
                );
                break;
            case VARIABLE_TYPES.COLOR:
                return (
                    <ColorField
                        label={label}
                        tooltip={tooltip}
                        enableAlpha={true}
                        presetColors={colorPresets}
                        pickerPosition='right'
                        value={presetItem.src !== null ? presetItem.src : DEFAULT_COLOR}
                        onChange={srcHandler}
                    />
                );
                break;
        }
    }

    public getAdditionalFields() {
        const { config } = this.props;
        if (config.type.toLowerCase() === VARIABLE_TYPES.ENUM) {
            return (
                <ListField
                    options={config.defaultItem.options}
                    onFocus={() => this.unBindHotkeys()}
                    onBlur={() => this.bindHotkeys()}
                    label={copy.global.options}
                    tooltip={copy.variable.tooltipOptions}
                    tooltipAdd={copy.variable.tooltipAddOption}
                    onError={this.evtHandlers.logError}
                    onChange={this.evtHandlers.defaultOptions}
                />
            );
        } else if (config.type.toLowerCase() === VARIABLE_TYPES.VIDEO) {
            return (
                <>
                    <NumberField
                        label={copy.variable.maxDuration || null}
                        value={config['maxDuration']}
                        tooltip={copy.variable.tooltipMaxDuration}
                        onChange={this.evtHandlers.maxDuration}
                    />
                    <VideoVariableVersions
                        versions={config['versions'] || []}
                        onChange={this.evtHandlers.versions}
                    />
                    <CheckboxField
                        label={copy.variable.autoModerate || null}
                        value={config['autoModerate'] || false}
                        tooltip={copy.variable.tooltipAutoModerate}
                        onChange={this.evtHandlers.autoModerate}
                    />
                </>
            );
        }
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        this.props.onUpdate(newConfig.id, newConfig);
    }

    public idChanged(value) {
        const {
            addingNew,
            config,
            config: { type }
        } = this.props;

        const newConfig = { ...config };

        const updateName = addingNew && newConfig.id === newConfig.name;
        newConfig['id'] = value;

        if (updateName) {
            this.setState({ name: value });
            newConfig['name'] = value;
        }

        if (addingNew && type === VARIABLE_TYPES.COLOR) {
            if (!newConfig.defaultItem.src) {
                newConfig.defaultItem.src = DEFAULT_COLOR;
            }
        }

        this.props.onUpdate(newConfig.id, newConfig);
    }

    private typeChanged(value) {
        const newConfig = { ...this.props.config };
        newConfig.type = value;
        newConfig.defaultItem =
            newConfig.type === VARIABLE_TYPES.COLOR
                ? { ...NEW_COLOR_VARIABLE_DEFAULT }
                : { ...NEW_VARIABLE_DEFAULT };
        newConfig.defaultItem.type = value;
        newConfig.previewItem = { ...NEW_VARIABLE_DEFAULT };
        newConfig.previewItem.type = value;

        this.props.onUpdate(newConfig.id, newConfig);
    }

    private confirmDelete() {
        const { config } = this.props;

        openConfirmModal({
            onYes: () => this.props.onDelete(this.props.config.id),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private isReserved(name) {
        const {
            editor: { fromCrM }
        } = this.props;

        if (fromCrM) {
            return false;
        } else if (RESERVED_VARIABLE_IDS.indexOf(name) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    private renderLowerButtons() {
        const {
            addingNew,
            config,
            editor: { fromCrM }
        } = this.props;
        const { unlocked } = this.state;
        const editIcon = unlocked ? ICON_LOCK : ICON_EDIT;
        const editTooltip = unlocked ? copy.variable.tooltipLock : copy.variable.tooltipEdit;
        const btnEdit = config.integration ? (
            <Button
                tooltip={editTooltip}
                style={'subtle'}
                onClick={() => this.setState({ unlocked: !unlocked })}>
                {editIcon}
            </Button>
        ) : null;

        if (!addingNew) {
            return (
                <>
                    {config.integration && btnEdit}
                    <Button
                        size='small'
                        style='subtle'
                        tooltip={copy.global.duplicate}
                        onClick={this.evtHandlers.duplicate}>
                        {ICON_COPY}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        tooltip={copy.global.delete}
                        onClick={this.evtHandlers.confirmDelete}>
                        {ICON_TRASH}
                    </Button>
                </>
            );
        } else {
            const isNameInvalid = addingNew && this.isReserved(config.name);

            const disableSave =
                config.id === '' || isNameInvalid || (fromCrM && !config.defaultItem.src)
                    ? true
                    : false;
            return (
                <>
                    <Button
                        size='small'
                        style='subtle'
                        color='danger'
                        tooltip={copy.global.cancel}
                        onClick={this.evtHandlers.cancel}>
                        {ICON_TIMES}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        color='primary'
                        disabled={disableSave}
                        tooltip={copy.variable.tooltipNewVar}
                        onClick={this.evtHandlers.save}>
                        {ICON_CHECK}
                    </Button>
                </>
            );
        }
    }

    private renderIDField() {
        const { config, addingNew } = this.props;
        const tooltip = !addingNew ? copy.variable.tooltipIDExisting : null;
        const rule = addingNew ? <HRule style='subtle' /> : null;
        const info = addingNew ? copy.variable.idPrompt : null;
        const id = config['id'] ? config['id'] : '';

        return (
            <>
                <TextField
                    ref={this.idFieldRef}
                    label={copy.global.id}
                    tooltip={tooltip}
                    readOnly={!addingNew}
                    disabled={!addingNew}
                    showCopy={!addingNew}
                    value={id}
                    pattern={/^$|^[a-zA-Z0-9_-]+$/}
                    info={info}
                    onChange={this.evtHandlers.id}
                    maxLength={100}
                />
                {rule}
            </>
        );
    }

    public render() {
        const {
            config,
            addingNew,
            editor: { fromCrM }
        } = this.props;
        const titleColor = addingNew ? 'text-primary' : '';
        const { unlocked, name } = this.state;
        const lockFields = config.integration && !unlocked;
        const isNameInvalid = addingNew && this.isReserved(config.name);
        const nameError = isNameInvalid ? (
            <span className='variable-error'>
                {copy.variable.errorReserved.replace('[id]', config.name)}
            </span>
        ) : null;
        const variableConfig = (
            <>
                <TextField
                    label={copy.global.name}
                    tooltip={copy.variable.tooltipName}
                    value={name}
                    onChange={(c) => this.setState({ name: c })}
                    onBlur={() => this.inputChanged('name', name)}
                />

                {nameError}

                <SelectField
                    label={copy.global.type}
                    tooltip={copy.variable.tooltipType}
                    value={config['type']}
                    disable={!addingNew}
                    options={VARIABLE_TYPE_OPTIONS}
                    onChange={this.evtHandlers.typeChanged}
                />

                {this.getAdditionalFields()}

                {this.getPresetField(
                    config.defaultItem,
                    copy.global.default,
                    copy.variable.tooltipDefault,
                    this.evtHandlers.defaultSrc,
                    this.evtHandlers.mediaDefaultChanged,
                    this.evtHandlers.clearMediaDefault,
                    fromCrM
                )}

                {this.getPresetField(
                    config.previewItem,
                    copy.global.preview,
                    copy.variable.tooltipPreview,
                    this.evtHandlers.previewSrc,
                    this.evtHandlers.mediaPreviewChanged,
                    this.evtHandlers.clearMediaPreview
                )}

                <CheckboxField
                    label={copy.global.optional}
                    tooltip={copy.variable.tooltipOptional}
                    value={config['optional']}
                    width={'50%'}
                    onChange={this.evtHandlers.optional}
                />

                <CheckboxField
                    label={copy.variable.returnViaAPI}
                    tooltip={copy.variable.tooltipReturn}
                    value={config['includeInExperienceData']}
                    width='50%'
                    onChange={this.evtHandlers.includeInExp}
                />

                <HRule style='subtle' />
            </>
        );

        return (
            <Card
                title={
                    <span className={`${titleColor}`}>
                        {getVariableIcon(config.type)}&nbsp;{config['name']}
                    </span>
                }
                collapsable={!addingNew}
                open={addingNew}>
                {this.renderIDField()}
                {!lockFields && variableConfig}
                <div style={{ textAlign: 'right' }}>{this.renderLowerButtons()}</div>
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return { project: state.project, editor: state.editor };
};

export default connect(mapStateToProps, mapDispatchToProps)(Variable);
