import * as React from 'react';
import {
    Section,
    Card,
    NumberField,
    TextField,
    Button,
    AnchorField
} from '@imposium-hub/components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    IVariables,
    ICompositionLayer,
    ILayerAnchor,
    ILayeredComposition
} from '../constants/snippets';
import MediaLayerOptions from './MediaLayerOptions';
import LayerEffectConfig from './LayerEffectConfig';
import type { IProject } from '../redux/reducers/project';
import { fields as copy } from '../constants/copy';
import { COMPOSITION_LAYER_TYPES, RESIZE_TYPES } from '../constants/story';
import TemplateLayerOptions from './TemplateLayerOptions';
import TextLayerOptions, { getCopyPropIdButton } from './TextLayerOptions';
import { ICON_COPY, ICON_TRASH, ICON_WAVEFORM } from '../constants/icons';
import LayerAnchorConfig from './LayerAnchorConfig';
import { getLayerOptions, newTTSAudioLayer } from '../util/story';
import AudioLayerEffectConfig from './AudioLayerEffectConfig';
import TextLayerAnimation from './TextLayerAnimation';
import { VariableMappingConfig } from './VariableMappingConfig';
import SolidLayerOptions from './SolidLayerOptions';
import { addLayer } from '../redux/actions/compositions';
import { updateTimelineState } from '../redux/actions/timeline';
import { logError, logNotification } from '../util/notifications';
import { openConfirmModal } from '../util/ui';

interface ILayerConfigProps {
    showCopyPropIds: boolean;
    project: IProject;
    compositions: ILayeredComposition[];
    compositionId: string;
    layerSources: any;
    width: number;
    height: number;
    rate: number;
    layer: ICompositionLayer;
    variables: IVariables;
    allLayers: ICompositionLayer[];
    updateLayer(layer: ICompositionLayer): void;
    updateAnchor(layerId: string, targetLayerId: string, start: boolean, targetStart: boolean);
    deleteAnchor(layerId: string, start: boolean);
    deleteLayer(layerId: string): any;
    duplicateLayer(layerId: string): any;
    addLayer(id, l, i?): void;
    updateTimelineState(state): void;
}

class LayerConfig extends React.PureComponent<ILayerConfigProps> {
    private evtHandlers = {
        effects: (e) => this.updateLayerFields({ effects: e }),
        options: (o) => this.updateLayerFields({ options: o }),
        name: (n) => this.updateLayerFields({ name: n }),
        startFrame: (s) => this.updateLayerFields({ start_frame: s }),
        endFrame: (e) => this.updateLayerFields({ end_frame: e }),
        offsetFrame: (o) => this.updateLayerFields({ offset_frames: o }),
        deleteStartAnchor: () => this.deleteLayerAnchor(true),
        deleteEndAnchor: () => this.deleteLayerAnchor(false),
        updateAnchorStart: (e) => this.updateLayerAnchor(true, e),
        updateAnchorEnd: (e) => this.updateLayerAnchor(false, e),
        duplicateLayer: () => this.duplicateLayer(),
        textToSpeechLayer: () => this.textToSpeechLayer(),
        inputs: {
            anchor: (v, h) => this.updateAnchor(v, h)
        },
        onNotification: (e) => logNotification(e),
        onError: (e) => logError(e)
    };

    private layerButtons: any;

    constructor(props) {
        super(props);

        this.layerButtons = [
            <Button
                key='btn-duplicate'
                size='small'
                style='subtle'
                tooltip={copy.global.duplicate}
                onClick={this.evtHandlers.duplicateLayer}>
                {ICON_COPY}
            </Button>,
            <Button
                key='btn-delete'
                size='small'
                style='subtle'
                tooltip={copy.global.delete}
                onClick={() => this.confirmDelete()}>
                {ICON_TRASH}
            </Button>
        ];
    }

    private duplicateLayer() {
        this.props.duplicateLayer(this.props.layer.id);
    }

    private textToSpeechLayer() {
        const {
            width,
            height,
            compositionId,
            layer: {
                start_frame,
                end_frame,
                name,
                options: { content }
            }
        } = this.props;
        const totalFrames = end_frame - start_frame;
        const layer = newTTSAudioLayer(
            COMPOSITION_LAYER_TYPES.AUDIO,
            width,
            height,
            width,
            height,
            totalFrames,
            content,
            `${name} Text to Speech`
        );
        this.props.addLayer(compositionId, layer);
        this.props.updateTimelineState({ activeTimelineLayer: layer.id, activeKeyframes: [] });
    }

    private confirmDelete() {
        const { layer } = this.props;
        openConfirmModal({
            onYes: () => this.props.deleteLayer(layer.id),
            title: copy.global.deletePrompt.replace('[name]', layer.name)
        });
    }

    private updateLayerFields(layer) {
        const merged = { ...this.props.layer, ...layer };
        this.props.updateLayer(merged);
    }

    private renderLayerOptions() {
        const {
            layer: { type, options, end_frame, start_frame, id },
            variables,
            compositionId,
            project: { storyId }
        } = this.props;

        const dur = end_frame - start_frame;
        switch (type) {
            case COMPOSITION_LAYER_TYPES.IMAGE:
            case COMPOSITION_LAYER_TYPES.VIDEO:
            case COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE:
            case COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION:
            case COMPOSITION_LAYER_TYPES.AUDIO:
                return (
                    <MediaLayerOptions
                        key={`layer-options-${id}`}
                        variables={variables}
                        storyId={storyId}
                        type={type}
                        options={options}
                        onChange={this.evtHandlers.options}
                        duration={dur}
                        layerId={id}
                    />
                );

            case COMPOSITION_LAYER_TYPES.TEMPLATE:
            case COMPOSITION_LAYER_TYPES.HTML:
                return (
                    <TemplateLayerOptions
                        key={`layer-options-${id}`}
                        variables={variables}
                        storyId={storyId}
                        type={type}
                        options={options}
                        onChange={this.evtHandlers.options}
                    />
                );

            case COMPOSITION_LAYER_TYPES.TEXT:
                return (
                    <>
                        <TextLayerOptions
                            compositionId={compositionId}
                            layerId={id}
                            key={`layer-options-${id}`}
                            variables={variables}
                            storyId={storyId}
                            type={type}
                            options={options}
                            onChange={this.evtHandlers.options}
                        />
                        <TextLayerAnimation
                            key={`layer-animation-${id}`}
                            storyId={storyId}
                            options={options}
                            onChange={this.evtHandlers.options}
                        />
                    </>
                );

            case COMPOSITION_LAYER_TYPES.SOLID:
                return (
                    <SolidLayerOptions
                        variables={variables}
                        type={type}
                        options={options}
                        onChange={this.evtHandlers.options}
                    />
                );
            default:
                return null;
        }
    }

    private deleteLayerAnchor(start: boolean) {
        this.props.deleteAnchor(this.props.layer.id, start);
    }

    private updateLayerAnchor(start: boolean, config: ILayerAnchor): void {
        // TODO: fix this mess. pass the anchor config vs id + boolean
        this.props.updateAnchor(
            this.props.layer.id,
            config.id,
            start,
            config.attachment_point === 'start_frame' ? true : false
        );
    }

    private renderEffectInputs() {
        const {
            layer: { effects, type },
            project: { storyId }
        } = this.props;

        if (type === COMPOSITION_LAYER_TYPES.AUDIO) {
            return (
                <AudioLayerEffectConfig
                    storyId={storyId}
                    config={effects || {}}
                    onChange={this.evtHandlers.effects}
                />
            );
        } else {
            return (
                <LayerEffectConfig
                    storyId={storyId}
                    config={effects || {}}
                    layerType={type}
                    onChange={this.evtHandlers.effects}
                />
            );
        }
    }

    private renderVariablesMapping() {
        const {
            variables,
            layer: { type, options }
        } = this.props;

        if (type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION) {
            return (
                <VariableMappingConfig
                    layerVars={[]}
                    variables={variables}
                    layerOptions={options}
                    onChange={this.evtHandlers.options}
                />
            );
        }
    }

    private updateAnchor(v, h) {
        const newConfig = { ...this.props.layer.position_inputs };
        newConfig['vertical_align'] = v;
        newConfig['horizontal_align'] = h;
        this.updateLayerFields({ position_inputs: newConfig });
    }

    private renderAnchorField() {
        const {
            layer: {
                position_inputs,
                position_inputs: { aspect_mode }
            }
        } = this.props;

        if (aspect_mode && aspect_mode !== RESIZE_TYPES.STRETCH) {
            return (
                <AnchorField
                    width='100%'
                    label={copy.global.anchor}
                    config={position_inputs}
                    onChange={this.evtHandlers.inputs.anchor}
                />
            );
        }
    }

    public render() {
        const {
            allLayers,
            showCopyPropIds,
            layer: {
                name,
                start_frame,
                end_frame,
                id,
                anchor_start,
                anchor_end,
                offset_frames,
                type
            },
            project: { compositionId }
        } = this.props;
        const dur = end_frame - start_frame;
        const layerOpts = getLayerOptions(allLayers, [id]);

        if (type === COMPOSITION_LAYER_TYPES.TEXT) {
            this.layerButtons = [
                <Button
                    key='btn-waveForm'
                    size='small'
                    style='subtle'
                    tooltip={copy.layerConfig.generateTTS}
                    onClick={this.evtHandlers.textToSpeechLayer}>
                    {ICON_WAVEFORM}
                </Button>,
                <Button
                    key='btn-duplicate'
                    size='small'
                    style='subtle'
                    tooltip={copy.global.duplicate}
                    onClick={this.evtHandlers.duplicateLayer}>
                    {ICON_COPY}
                </Button>,
                <Button
                    key='btn-delete'
                    size='small'
                    style='subtle'
                    tooltip={copy.global.delete}
                    onClick={() => this.confirmDelete()}>
                    {ICON_TRASH}
                </Button>
            ];
        }

        return (
            <Section
                title={name}
                buttons={this.layerButtons}>
                {this.renderLayerOptions()}

                <Card
                    title={copy.layerConfig.info}
                    open={false}
                    collapsable={true}
                    style={`info`}>
                    <TextField
                        label={copy.layerConfig.name}
                        tooltip={copy.layerConfig.tooltipName}
                        value={name}
                        onChange={this.evtHandlers.name}
                    />
                    <TextField
                        label={copy.layerConfig.id}
                        readOnly={true}
                        showCopy={true}
                        width='100%'
                        value={id}
                    />
                    <NumberField
                        label={
                            <span>
                                {copy.layerConfig.start}
                                {getCopyPropIdButton(
                                    showCopyPropIds,
                                    compositionId,
                                    id,
                                    'start_frame',
                                    this.evtHandlers.onNotification,
                                    this.evtHandlers.onError
                                )}
                            </span>
                        }
                        tooltip={copy.layerConfig.tooltipStartFrame}
                        width='50%'
                        value={start_frame}
                        min={0}
                        onChange={this.evtHandlers.startFrame}
                    />
                    <NumberField
                        label={
                            <span>
                                {copy.layerConfig.end}
                                {getCopyPropIdButton(
                                    showCopyPropIds,
                                    compositionId,
                                    id,
                                    'end_frame',
                                    this.evtHandlers.onNotification,
                                    this.evtHandlers.onError
                                )}
                            </span>
                        }
                        tooltip={copy.layerConfig.tooltipEndFrame}
                        width='50%'
                        value={end_frame}
                        min={0}
                        onChange={this.evtHandlers.endFrame}
                    />
                    <NumberField
                        label={copy.global.duration}
                        tooltip={copy.layerConfig.tooltipEndFrame}
                        width='50%'
                        readOnly={true}
                        value={dur}
                    />
                    <NumberField
                        label={
                            <span>
                                {copy.layerConfig.offsetFrames}
                                {getCopyPropIdButton(
                                    showCopyPropIds,
                                    compositionId,
                                    id,
                                    'offset_frames',
                                    this.evtHandlers.onNotification,
                                    this.evtHandlers.onError
                                )}
                            </span>
                        }
                        tooltip={copy.layerConfig.tooltipOffsetFrames}
                        width='50%'
                        value={offset_frames}
                        min={0}
                        onChange={this.evtHandlers.offsetFrame}
                    />
                    <LayerAnchorConfig
                        config={anchor_start}
                        layerOptions={layerOpts}
                        label={copy.layerConfig.anchorStart}
                        onUpdate={this.evtHandlers.updateAnchorStart}
                        onDelete={this.evtHandlers.deleteStartAnchor}
                    />
                    <LayerAnchorConfig
                        config={anchor_end}
                        layerOptions={layerOpts}
                        label={copy.layerConfig.anchorEnd}
                        onUpdate={this.evtHandlers.updateAnchorEnd}
                        onDelete={this.evtHandlers.deleteEndAnchor}
                    />
                    {this.renderAnchorField()}
                    {this.renderVariablesMapping()}
                </Card>

                {this.renderEffectInputs()}
            </Section>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ addLayer, updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        project: state.project,
        showCopyPropIds: state.editor.showCopyPropIds,
        layerSources: state.layerSources,
        compositions: state.compositions.present
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerConfig);
