import * as PIXI from 'pixi.js-legacy';
import parse from 'color-parse';
import gsap from 'gsap';
import { degreesToRadians, rgbToHex, s3Url } from '../../../util/general';
import { formatTextContent, parseInventoryFromVariables } from '../../../util/story';
import {
    FONT_TYPES,
    MAX_FADE_DURATION,
    MAX_REVEAL_DURATION,
    TEXT_LAYER_ANIMATIONS
} from '../../../constants/story';
import { ASSET_DISTRO, ASSET_ORIGIN } from '../../../constants/editor';
import { DEFAULT_FONTS } from '../../../constants/fonts';
import { getInterpolatedConfigValue, getLayerPosition } from '../../../util/timeline';
import LayerSource from './LayerSource';

export default class TextLayerSource extends LayerSource {
    private bgSprite: any;

    private textSprite: any;

    private font: any;

    private mask: any;

    private animationTimeline: any;

    private lastOffsetFrame: number = undefined;

    private loadedStandardFont: string;

    private loadedCustomFont: string;

    private previousOptions: any;

    private previousCopy: string;

    private previousWidth: number;

    private previousHeight: number;

    public componentDidMount(): void {
        super.componentDidMount();
        if (!this.props.inView) {
            return;
        }
        void this.updateText();
    }

    public componentDidUpdate(prevProps: any, prevState: any): void {
        super.componentDidUpdate(prevProps, prevState);

        if (!this.props.inView) {
            return;
        }

        if (this.props.mounted) {
            void this.updateText();
        }
    }

    private loadFont() {
        return new Promise<void>((resolve, reject) => {
            const {
                layerData: {
                    options: { font, font_size, font_type, custom_font_url, font_weight }
                },
                renderMode
            } = this.props;

            if (font_type === FONT_TYPES.CUSTOM && font_size && custom_font_url) {
                const fontOrigin = s3Url(custom_font_url, ASSET_DISTRO, ASSET_ORIGIN);

                if (!(this.loadedCustomFont === font)) {
                    this.logInfo(`Load font: ${font}`);
                    const fontObj = new FontFace(font, `url(${fontOrigin})`, {
                        style: 'normal',
                        weight: 'normal'
                    });
                    fontObj
                        .load()
                        .then((e) => {
                            document.fonts.add(fontObj);
                            this.loadedCustomFont = font;
                            this.logInfo(`Font loaded: ${font}`);
                            resolve(font);
                        })
                        .catch((e) => {
                            reject();
                        });
                } else {
                    resolve(font);
                }
            } else if (font_type === FONT_TYPES.STANDARD) {
                const standardObj = DEFAULT_FONTS.find((f) => f.name === font);
                const fontBase = renderMode ? 'fonts' : '/fonts';

                if (!(this.loadedStandardFont === font)) {
                    this.logInfo(`Load font: ${font}`);
                    const fontObj = new FontFace(font, `url(${fontBase}/${standardObj.file})`, {
                        style: 'normal',
                        weight: font_weight || 'normal'
                    });
                    fontObj
                        .load()
                        .then((e) => {
                            document.fonts.add(fontObj);
                            this.loadedStandardFont = font;
                            this.logInfo(`Font loaded: ${font}`);
                            resolve(font);
                        })
                        .catch((e) => {
                            reject();
                        });
                } else {
                    resolve(font);
                }
            }
        });
    }

    public resize(interimPosition = null) {
        void this.updateText();
        if (this.lastOffsetFrame !== undefined) {
            this.applyAnimations();
        }
    }

    public clear() {
        if (this.bgSprite) {
            if (this.mediaSprite) {
                this.mediaSprite.removeChild(this.bgSprite);
            }
            this.bgSprite.destroy(true);
            this.bgSprite = null;
        }

        if (this.textSprite) {
            if (this.mediaSprite) {
                this.mediaSprite.removeChild(this.textSprite);
            }
            this.textSprite.destroy(true);
            this.textSprite = null;
        }

        if (this.mediaSprite) {
            this.contentSprite.removeChild(this.mediaSprite);
            this.mediaSprite.destroy(true);
            this.mediaSprite = null;
        }

        if (this.animationTimeline) {
            this.animationTimeline.clear();
            this.animationTimeline.kill();
        }
    }

    public applyAnimations() {
        const {
            relativeFrame,
            layerData: { keyframes }
        } = this.props;
        this.lastOffsetFrame = relativeFrame;

        // Apply bespoke animations, then apply opacity
        const frames = this.getLayerFrameDuration();
        const duration = this.getLayerTimeDuration();
        const percentageComplete = relativeFrame / frames;

        const time = duration * percentageComplete;

        if (this.animationTimeline) {
            this.animationTimeline.seek(time);
        }

        if (keyframes) {
            if (keyframes.opacity && keyframes.opacity.length > 0) {
                const opacity = getInterpolatedConfigValue(keyframes.opacity, relativeFrame);
                this.adjustmentFilter.alpha = opacity;
            }
        }
    }

    private getLayerFrameDuration = () => {
        const { start_frame, end_frame } = this.props.layerData;
        const layerDuration = end_frame - start_frame;
        return layerDuration;
    };

    private getLayerTimeDuration = () => {
        const frames = this.getLayerFrameDuration();
        const time = frames / this.props.frameRate;
        return time;
    };

    private createAnimationTimeline(x, y, width, height, fontSize) {
        const {
            layerData: {
                options: { animation_type, highlight_color }
            },
            variables,
            variableMap
        } = this.props;

        const inventory = parseInventoryFromVariables(variables, variableMap);
        const highlightColor = formatTextContent(highlight_color, inventory);
        const baselineScale = this.textSprite.scale.x;
        const duration = this.getLayerTimeDuration();
        const fadeTime = Math.min(duration / 4, MAX_FADE_DURATION);
        const moveFactor = 0.25;
        const zoomScaleFactor = 0.8;
        this.animationTimeline = gsap.timeline({ paused: true });

        switch (animation_type) {
            case TEXT_LAYER_ANIMATIONS.FADE:
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { alpha: 0 },
                        { alpha: 1, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { alpha: 0, duration: fadeTime, ease: 'power1.out' }),
                    duration - fadeTime
                );

                break;

            case TEXT_LAYER_ANIMATIONS.MOVE_LEFT:
            case TEXT_LAYER_ANIMATIONS.MOVE_RIGHT:
                const xShift =
                    animation_type === TEXT_LAYER_ANIMATIONS.MOVE_RIGHT
                        ? -width * moveFactor
                        : width * moveFactor;
                const animX = x + xShift;
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { alpha: 0 },
                        { alpha: 1, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { x: animX },
                        { x, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { alpha: 0, duration: fadeTime, ease: 'power1.in' }),
                    duration - fadeTime
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { x: animX, duration: fadeTime, ease: 'power1.in' }),
                    duration - fadeTime
                );

                break;

            case TEXT_LAYER_ANIMATIONS.MOVE_UP:
            case TEXT_LAYER_ANIMATIONS.MOVE_DOWN:
                const yShift =
                    animation_type === TEXT_LAYER_ANIMATIONS.MOVE_DOWN
                        ? -height * moveFactor
                        : height * moveFactor;
                const animY = y + yShift;
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { alpha: 0 },
                        { alpha: 1, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { y: animY },
                        { y, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { alpha: 0, duration: fadeTime, ease: 'power1.in' }),
                    duration - fadeTime
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { y: animY, duration: fadeTime, ease: 'power1.in' }),
                    duration - fadeTime
                );

                break;

            case TEXT_LAYER_ANIMATIONS.ZOOM_IN:
                const startScale = baselineScale * zoomScaleFactor;

                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite,
                        { alpha: 0 },
                        { alpha: 1, duration: fadeTime, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.to(this.textSprite, { alpha: 0, duration: fadeTime, ease: 'power1.in' }),
                    duration - fadeTime
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.textSprite.scale,
                        { x: startScale, y: startScale },
                        { x: baselineScale, y: baselineScale, duration, ease: 'linear' }
                    ),
                    0
                );

                break;

            case TEXT_LAYER_ANIMATIONS.BOX_REVEAL:
                const revealDuration = Math.min(duration / 2, MAX_REVEAL_DURATION);
                const lineSize = Math.floor(fontSize * 0.2);
                const borderPadding = fontSize;
                const borderWidth = width + borderPadding;
                const borderHeight = height + borderPadding;
                const borderX = x - borderWidth / 2;
                const borderY = y - borderHeight / 2;

                this.mask = getMask(x, y, width, height);
                this.mask.scale.x = 0.01;
                this.mask.x = x;
                this.textSprite.mask = this.mask;
                this.mediaSprite.addChild(this.mask);

                const borderTop = getRect(borderWidth, lineSize, highlightColor);
                borderTop.x = borderX;
                borderTop.y = borderY;

                const borderRight = getRect(borderHeight, lineSize, highlightColor);
                borderRight.rotation = degreesToRadians(90);
                borderRight.x = borderX + borderWidth;
                borderRight.y = borderY;

                const borderLeft = getRect(borderHeight, lineSize, highlightColor);
                borderLeft.rotation = degreesToRadians(270);
                borderLeft.x = borderX;
                borderLeft.y = borderY + borderHeight;

                const borderBottom = getRect(borderWidth, lineSize, highlightColor);
                borderBottom.rotation = degreesToRadians(180);
                borderBottom.x = borderX + borderWidth;
                borderBottom.y = borderY + borderHeight;

                this.mediaSprite.addChild(borderTop);
                this.mediaSprite.addChild(borderRight);
                this.mediaSprite.addChild(borderLeft);
                this.mediaSprite.addChild(borderBottom);

                const boxPerimeter = 2 * (width + height);
                const verticalDuration = (revealDuration * height) / boxPerimeter;
                const horizontalDuration = (revealDuration * width) / boxPerimeter;

                // on
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.mask.scale,
                        { x: 0 },
                        { x: 1, duration: revealDuration * 0.5, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        this.mask,
                        { x },
                        { x: x - width / 2, duration: revealDuration * 0.5, ease: 'power1.out' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        borderTop.scale,
                        { x: 0 },
                        { x: 1, duration: horizontalDuration, ease: 'power1.in' }
                    ),
                    0
                );
                this.animationTimeline.add(
                    gsap.fromTo(borderRight.scale, { x: 0 }, { x: 1, duration: verticalDuration }),
                    horizontalDuration
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        borderBottom.scale,
                        { x: 0 },
                        { x: 1, duration: horizontalDuration }
                    ),
                    horizontalDuration + verticalDuration
                );
                this.animationTimeline.add(
                    gsap.fromTo(
                        borderLeft.scale,
                        { x: 0 },
                        { x: 1, duration: verticalDuration, ease: 'power1.out' }
                    ),
                    horizontalDuration * 2 + verticalDuration
                );

                // off
                const offDly = duration - revealDuration;
                this.animationTimeline.add(
                    gsap.to(this.mask.scale, {
                        x: 0.00001,
                        duration: revealDuration * 0.5,
                        ease: 'power1.out'
                    }),
                    offDly
                );
                this.animationTimeline.add(
                    gsap.to(this.mask, { x, duration: revealDuration * 0.5, ease: 'power1.out' }),
                    offDly
                );
                this.animationTimeline.add(
                    gsap.to(borderTop.scale, {
                        x: 0,
                        duration: horizontalDuration,
                        ease: 'power1.out'
                    }),
                    offDly + verticalDuration * 2 + horizontalDuration
                );
                this.animationTimeline.add(
                    gsap.to(borderRight.scale, { x: 0, duration: verticalDuration }),
                    offDly + (verticalDuration + horizontalDuration)
                );
                this.animationTimeline.add(
                    gsap.to(borderBottom.scale, { x: 0, duration: horizontalDuration }),
                    offDly + verticalDuration
                );
                this.animationTimeline.add(
                    gsap.to(borderLeft.scale, {
                        x: 0,
                        duration: verticalDuration,
                        ease: 'power1.in'
                    }),
                    offDly
                );
        }
    }

    private getPositionFromLayerData(applyScale = true, applyAnchorOffset = true) {
        const {
            compWidth,
            compHeight,
            layerData: { position_inputs, keyframes },
            relativeFrame
        } = this.props;

        return getLayerPosition(
            position_inputs,
            keyframes,
            relativeFrame,
            compWidth,
            compHeight,
            applyScale,
            applyAnchorOffset
        );
    }

    private generateTextSprite = (copy, options, width, height) => {
        const { variables, variableMap } = this.props;

        if (
            copy === this.previousCopy &&
            options === this.previousOptions &&
            width === this.previousWidth &&
            height === this.previousHeight
        ) {
            return;
        }

        this.logInfo(`Generate Text Sprite`);

        this.clear();

        const {
            text_wrap,
            text_fit,
            color,
            letter_spacing,
            horizontal_alignment,
            vertical_alignment,
            line_height,
            font_size,
            stroke_color,
            background_color,
            stroke_weight,
            font_style
        } = options;

        const inventory = parseInventoryFromVariables(variables, variableMap);
        const colorValue = formatTextContent(color, inventory);
        const strokeColor = formatTextContent(stroke_color, inventory);
        const backgroundColor = formatTextContent(background_color, inventory);
        const parsedbgColor = parse(backgroundColor);

        const bgHex = rgbToHex(
            parsedbgColor.values[0],
            parsedbgColor.values[1],
            parsedbgColor.values[2]
        );

        this.mediaSprite = new PIXI.Sprite();

        this.contentSprite.addChild(this.mediaSprite);

        this.bgSprite = new PIXI.Graphics();
        this.bgSprite.beginFill(0x000000, 0.000001); // "invisible" fill for the onCLick event
        this.bgSprite.drawRect(0, 0, width, height);
        this.bgSprite.beginFill(bgHex, parsedbgColor.alpha);
        this.bgSprite.drawRect(0, 0, width, height);
        this.mediaSprite.addChild(this.bgSprite);
        this.bgSprite.eventMode = 'dynamic';
        this.bgSprite.on('click', (e) => this.onClick(e));

        this.previousCopy = copy;
        this.previousWidth = width;
        this.previousHeight = height;
        this.previousOptions = options;

        const fStyle = font_style || 'normal';
        const getTextStyle = (fS, lH) => {
            const fontSize = fS || 1;
            const padding = fontSize * 0.25;

            const textStyle: any = {
                padding,
                fill: colorValue,
                align: horizontal_alignment,
                lineHeight: lH,
                letterSpacing: letter_spacing,
                fontSize,
                wordWrap: text_wrap,
                wordWrapWidth: width,
                fontFamily: this.font,
                miterLimit: 3,
                trim: true,
                fontStyle: fStyle
            };

            if (strokeColor !== 'transparent' && stroke_weight) {
                textStyle.stroke = strokeColor;
                textStyle.strokeThickness = stroke_weight;
            }

            return textStyle;
        };

        const getDims = (fS) => {
            lHeight = lRatio ? Math.floor(fS * lRatio) : null;
            const newStyle = new PIXI.TextStyle(getTextStyle(fS, lHeight));
            this.textSprite.style = newStyle;
            return this.textSprite.getLocalBounds();
        };

        const getFontSize = (fS) => {
            let start = 1;
            let end = fS;
            let checkStart = start;
            let checkEnd = end;

            while (start <= end) {
                if (fS === 1) {
                    break;
                }

                let middle = Math.floor((start + end) / 2);
                const newDims = getDims(middle);

                if (newDims.width < width && newDims.height < height) {
                    start = middle++;
                    if (checkStart !== start) {
                        checkStart = start;
                    } else {
                        return start;
                    }
                } else {
                    end = middle--;
                    if (checkEnd !== end) {
                        checkEnd = end;
                    } else {
                        return end;
                    }
                }
            }
        };

        const style = new PIXI.TextStyle(getTextStyle(font_size, line_height));

        this.textSprite = new PIXI.Text(copy, style);

        // initial text dimensions
        const dims = this.textSprite.getLocalBounds();
        let scale = 1;
        let lRatio;
        let fSize = font_size;
        let lHeight = line_height;
        let fitDims = this.textSprite.getLocalBounds();

        // scale to fit if selected
        if (text_fit && font_size) {
            if (lHeight !== undefined && lHeight !== null) {
                lRatio = fSize / lHeight;
            }

            if (fitDims.width > width || fitDims.height > height) {
                fSize = getFontSize(fSize);
                fitDims = getDims(fSize);
            }

            const computedScale = Math.min(width / fitDims.width, height / fitDims.height);
            if (computedScale < 1) {
                scale = computedScale;
                this.textSprite.scale.x = scale;
                this.textSprite.scale.y = scale;
            }
        }

        // position withing the bounds
        const textX =
            horizontal_alignment === 'left'
                ? 0
                : horizontal_alignment === 'right'
                ? width - dims.width * scale
                : (width - dims.width * scale) / 2;
        const textY =
            vertical_alignment === 'flex-start'
                ? 0
                : vertical_alignment === 'flex-end'
                ? height - dims.height * scale
                : (height - dims.height * scale) / 2;

        const textW = fitDims ? fitDims.width : width;

        const textH = fitDims ? fitDims.height : height;

        this.textSprite.anchor.set(0.5, 0.5);

        const offsetX = textX + textW / 2;
        const offsetY = textY + textH / 2;

        this.textSprite.x = offsetX;
        this.textSprite.y = offsetY;

        this.mediaSprite.addChild(this.textSprite);

        this.createAnimationTimeline(
            offsetX,
            offsetY,
            textW + style.padding,
            textH + style.padding,
            fSize
        );
    };

    private async updateText() {
        this.clearMask();

        const start = performance.now();

        const {
            interimPosition,
            layerData: {
                options,
                options: { content, upper_case }
            },
            variables,
            variableMap
        } = this.props;

        const inventory = parseInventoryFromVariables(variables, variableMap);

        this.logInfo(`Update Text Sprite`);

        try {
            this.font = await this.loadFont();
        } catch (e) {
            this.logError(`Error loading font`, e);
            this.font = 'Arial, helvetica, sans-serif';
        }

        if (options && this.font) {
            const realPos = this.getPositionFromLayerData(false, false);
            let { x, y, scaleX, scaleY, width, height } = realPos;
            const { anchorX, anchorY } = realPos;

            let copy = formatTextContent(content, inventory);
            if (upper_case) {
                copy = copy.toUpperCase();
            }

            if (interimPosition) {
                x = interimPosition.x + interimPosition.anchorX;
                y = interimPosition.y + interimPosition.anchorY;
                scaleX = interimPosition.scaleX;
                scaleY = interimPosition.scaleY;
                width = interimPosition.width / interimPosition.scaleX;
                height = interimPosition.height / interimPosition.scaleY;
            } else {
                this.generateTextSprite(copy, options, width, height);
            }

            if (this.mediaSprite) {
                // Apply the anchor offset with the scale applied, as the PIXI anchor does not affect transforms
                this.mediaSprite.x = x - anchorX * scaleX;
                this.mediaSprite.y = y - anchorY * scaleY;

                // Apply the scale to the container sprite
                this.mediaSprite.scale.set(scaleX, scaleY);
            }

            this.createMask(
                x - anchorX * scaleX,
                y - anchorY * scaleY,
                width * scaleX,
                height * scaleY
            );
            this.applyEffects();
            this.applyAnimations();
            this.dispatchDrawCompleteEvent();
        }

        const end = performance.now();
        const time = end - start;
        this.logInfo(`Draw complete in ${time.toFixed(2)}ms`, true);
        if (this.props.onReady) {
            this.props.onReady();
        }
    }

    public createMask(x, y, w, h) {
        this.clearMask();

        this.maskGraphic = new PIXI.Graphics();
        this.maskGraphic.beginFill(0xffffff);
        this.maskGraphic.drawRect(0, 0, w, h);
        this.maskGraphic.x = x;
        this.maskGraphic.y = y;

        this.contentSprite.addChild(this.maskGraphic);
        this.mediaSprite.mask = this.maskGraphic;
    }
}

export const getMask = (x, y, w, h): PIXI.Graphics => {
    const gfx = new PIXI.Graphics();
    gfx.beginFill(0xffffff);
    gfx.drawRect(0, 0, w, h);
    gfx.x = x + -w / 2;
    gfx.y = y + -h / 2;
    return gfx;
};

export const getRect = (w, h, color): PIXI.Graphics => {
    const c = color || 'rgba(255,255,255,1)';
    const parsedColor = parse(c);
    const hex = rgbToHex(parsedColor.values[0], parsedColor.values[1], parsedColor.values[2]);
    const gfx = new PIXI.Graphics();
    gfx.beginFill(hex);
    gfx.drawRect(0, 0, w, h);
    return gfx;
};
